import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Typography,
  Container,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  TextField,
} from "@mui/material";
import VideocamIcon from "@mui/icons-material/Videocam";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import DOMPurify from "dompurify";
import { useSelector, useDispatch } from "react-redux";
import {
  saveVideo,
  deleteVideo,
  editDescription,
  deleteDescription,
} from "../store/MyVideoSlice";
import axiosInstance from "./axiosConfig";

const MyVideos = () => {
  const dispatch = useDispatch();
  const [videos, setVideos] = useState([]);
  const error = useSelector((state) => state.myVideos.error);
  const videoDescriptions = useSelector(
    (state) => state.myVideos.videoDescriptions
  );

  const [selectedFile, setSelectedFile] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isConverting, setIsConverting] = useState(false);
  const [isEditing, setIsEditing] = useState(null);
  const [descriptions, setDescriptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const mediaRecorderRef = useRef(null);
  const recordedChunksRef = useRef([]);
  const [refresh, setRefresh] = useState(false);
  const [fetching, setFetching] = useState(true);
  const { csrfToken } = useSelector((state) => state.auth);

  useEffect(() => {
    if (videos.length > 0 && videoDescriptions.length > 0) {
      console.log("Video descriptions:", videoDescriptions);
      const updatedDescriptions = videos.map((video) => {
        const matchingDescription = videoDescriptions.find(
          (desc) => String(desc.Key) === String(video.Key)
        );
        return matchingDescription ? matchingDescription.text : "";
      });
      console.log("Updated description:", updatedDescriptions);
      setDescriptions(updatedDescriptions);
      console.log("Videodescriptions:", videoDescriptions);
    }
    console.log("Videos:", videos);
    console.log("Video Descriptions:", videoDescriptions);
  }, [videos, videoDescriptions]);

  useEffect(() => {
    fetchVideos();
    setRefresh(false);
  }, [refresh, csrfToken]);

  const fetchVideos = async () => {
    setFetching(true);
    try {
      const headResponse = await axiosInstance.head(
        "https://memorydiaries.com/lifeData/videos/download",
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
          timeout: 30000000,
        }
      );

      console.log("Head response:", headResponse.headers);

      const response = await axiosInstance.get(
        "https://memorydiaries.com/lifeData/videos/download",
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "csrf-token": csrfToken,
          },
          withCredentials: true,
          timeout: 30000000,
        }
      );
      console.log("Response data:", response.data);
      setVideos(response.data);
      if (Array.isArray(response.data)) {
        setDescriptions(response.data.map(() => ""));
      } else {
        console.error("Unexpected response format:", response.data);
      }
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
    setFetching(false);
  };

  useEffect(() => {
    console.log("useEffect triggered with selectedFile:", selectedFile);
    if (selectedFile && selectedFile.type === "video/webm") {
      console.log("Dispatching saveVideo with file:", selectedFile);
      dispatch(saveVideo(selectedFile));
      setSelectedFile(null);
      setRefresh(true);
      setIsConverting(false);
      setErrorMessage("");
    }
  }, [selectedFile, dispatch]);

  const allowedFormats = [
    "video/mp4",
    "video/avi",
    "video/quicktime", // For MOV files
    "video/x-matroska", // For MKV files
    "video/x-flv", // For FLV files
    "video/x-ms-wmv", // For WMV files
    "video/mpeg",
    "video/webm",
  ];
  const maxFileSize = 4 * 1024 * 1024 * 1024; // 4 GB

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const compressVideoFile = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file); // 'file' is the name expected by the backend multer middleware
      const response = await axiosInstance.post(
        "https://memorydiaries.com/lifeData/videos/convert",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "csrf-token": csrfToken,
          },
          responseType: "blob",
          withCredentials: true,
          timeout: 3000000,
        }
      );
      console.log("Response data:", response.data);
      const originalFileName = file.name.split(".").slice(0, -1).join(".");
      const newFileName = `${originalFileName}.webm`; // Change suffix to .webm
      const videoBlob = new Blob([response.data], { type: "video/webm" });
      console.log("Video blob:", videoBlob);
      const videoBlobWithName = new File([videoBlob], newFileName, {
        type: "video/webm",
      });
      console.log("Video blob with name:", videoBlobWithName);
      setSelectedFile(videoBlobWithName);
      setTimeout(() => {
        console.log("Compressed video file (inside setTimeout):", selectedFile);
      }, 100);
    } catch (error) {
      console.error("Error compressing video:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsConverting(true);

    if (!selectedFile) {
      setErrorMessage("Please select a file first.");
      setIsConverting(false);
      return;
    }

    if (selectedFile.size > maxFileSize) {
      setErrorMessage("File size exceeds 4 GB.");
      setSelectedFile(null);
      setIsConverting(false);
      return;
    }

    if (!allowedFormats.includes(selectedFile.type)) {
      setErrorMessage(
        "Invalid file format. Please upload a video in a supported format."
      );
      setSelectedFile(null);
      setIsConverting(false);
      return;
    }

    if (selectedFile.type === "video/webm") {
      await dispatch(saveVideo(selectedFile));
      setSelectedFile(null);
      setRefresh(true);
      setIsConverting(false);
      setErrorMessage("");
      return;
    } else {
      try {
        await compressVideoFile(selectedFile);
        console.log("Selected file (inside handleSubmit):", selectedFile);
      } catch (error) {
        console.error("Error during file processing:", error);
      }
    }
  };

  const handleRecordStart = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert(
        "Media devices not supported in this browser. Please use a different browser."
      );
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: "video/webm",
      });

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordedChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = async () => {
        const blob = new Blob(recordedChunksRef.current, {
          type: "video/webm",
        });
        const file = new File([blob], "recorded_video.webm", {
          type: "video/webm",
        });

        recordedChunksRef.current = [];
        stream.getTracks().forEach((track) => track.stop());

        // Dispatch the saveVideo action to save the recorded video
        await dispatch(saveVideo(file));
        setRefresh(true); // Refresh the video list after upload
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      alert("Permission to record denied");
    }
  };

  const handleDescriptionChange = (index, event) => {
    if (descriptions[index] !== undefined) {
      const sanitizedDescription = DOMPurify.sanitize(event.target.value);
      const newDescriptions = [...descriptions];
      newDescriptions[index] = sanitizedDescription;
      setDescriptions(newDescriptions);
    } else {
      console.error("Description index out of bounds");
    }
  };

  const handleSave = (key, index) => {
    dispatch(editDescription({ key: key, text: descriptions[index] }));
    setIsEditing(null);
  };

  const handleEdit = (index) => {
    setIsEditing(index);
  };

  const handleRecordStop = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const handleDelete = async (videoKey) => {
    await dispatch(deleteVideo(videoKey));
    dispatch(deleteDescription(videoKey));
    setRefresh(true); // Refresh the video list after deletion
  };

  const handleDownload = (base64, videoKey) => {
    const blob = base64ToBlob(base64, "video/webm");
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", `${videoKey}.webm`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const base64ToBlob = (base64, mimeType) => {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: mimeType });
  };

  return (
    <Container style={{ marginTop: 20 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        My Videos
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        Upload Videos Related to Your Story
      </Typography>
      <p>Allowed formats: MP4, AVI, MOV, MKV, FLV, WMV, MPEG, WebM</p>
      <p>If it is not one of these file types it will not upload</p>
      <p>Maximum file size: 2GB</p>
      <p>Larger files may take longer to compress</p>
      {errorMessage && (
        <Typography variant="body2" color="error" sx={{ mt: 2 }}>
          {errorMessage}
        </Typography>
      )}
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <input
          type="file"
          accept="video/*"
          onChange={handleFileChange}
          style={{ margin: "16px 0" }}
        />
        <Button
          type="submit"
          variant="contained"
          sx={{
            backgroundColor: "#E29578",
            "&:hover": {
              backgroundColor: "#FFDDD2",
            },
          }}
          disabled={isConverting}
        >
          {isConverting ? "Converting and Saving..." : "Upload"}
        </Button>
      </Box>
      <Box sx={{ mt: 4, display: "flex", alignItems: "center" }}>
        <Typography variant="h6" component="h2" gutterBottom>
          Record a Video
        </Typography>
        <IconButton
          color="default"
          onClick={isRecording ? handleRecordStop : handleRecordStart}
          aria-label="record ideo"
          sx={{ marginLeft: 2, color: isRecording ? "red" : "inherit" }}
        >
          <VideocamIcon />
        </IconButton>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" component="h2" gutterBottom>
          Uploaded Videos
        </Typography>
        {fetching ? (
          <Typography variant="body1">Loading videos...</Typography>
        ) : videos.length > 0 ? (
          <List>
            {videos.map((video, index) => {
              const blob = base64ToBlob(video.base64, "video/webm");
              const videoUrl = URL.createObjectURL(blob);

              return (
                <ListItem
                  key={index}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <video width="320" height="240" controls>
                    <source src={videoUrl} type="video/webm" />
                    Your browser does not support the video tag.
                  </video>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "16px",
                      flexGrow: 1,
                    }}
                  >
                    <ListItemText
                      primary={video.Key || `Video ${index + 1}`}
                      secondary={descriptions[index]}
                      sx={{ marginLeft: "20px" }}
                    />
                    {isEditing === index ? (
                      <>
                        <TextField
                          value={descriptions[index]}
                          onChange={(event) =>
                            handleDescriptionChange(index, event)
                          }
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={6}
                          placeholder="Add a description"
                          margin="normal"
                        />
                        <IconButton
                          aria-label="save"
                          onClick={() => handleSave(video.Key, index)}
                        >
                          <SaveIcon />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <Typography variant="body1">
                          {descriptions[index]}
                        </Typography>
                        <IconButton
                          aria-label="edit"
                          onClick={() => handleEdit(index)}
                        >
                          <EditIcon />
                        </IconButton>
                      </>
                    )}
                  </Box>
                  <IconButton
                    edge="end"
                    aria-label="download"
                    onClick={() => handleDownload(video.base64, video.Key)}
                    sx={{ marginLeft: "16px" }}
                  >
                    <DownloadIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDelete(video.Key)}
                    sx={{ marginLeft: "16px" }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              );
            })}
          </List>
        ) : (
          <Typography variant="body1">No videos uploaded yet.</Typography>
        )}
      </Box>
      {error && (
        <Typography variant="body2" color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
    </Container>
  );
};

export default MyVideos;
