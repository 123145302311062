import React, { useState, useEffect, useRef } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import axiosInstance from "./axiosConfig";
import { generateNonce } from "../utils/generateNonce";
import { useSelector } from "react-redux";
import { initializeStripe } from "../stripe-init";

const UpdatePayment = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const csrfToken = useSelector((state) => state.auth.csrfToken);
  const clientSecret = useSelector((state) => state.stripe.clientSecret);
  const [customerId, setCustomerId] = useState("");
  const [subscriptionId, setSubscriptionId] = useState("");
  const [selectedPlan, setSelectedPlan] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const paymentElementRef = useRef(null);

  useEffect(() => {
    initializeStripe(clientSecret);
    return () => {};
  }, []);

  useEffect(() => {
    const fetchPaymentInfo = async () => {
      try {
        const response = await axiosInstance.get(
          "https://memorydiaries.com/lifestripe/payment-info",
          {
            headers: { "csrf-token": csrfToken },
            withCredentials: true,
          }
        );
        const { customerId, subscriptionId } = response.data;
        console.log("Fetched payment info:", customerId, subscriptionId);
        setCustomerId(customerId);
        setSubscriptionId(subscriptionId);
      } catch (err) {
        console.error("Error fetching payment info:", err);
        setMessage("Failed to retrieve payment info.");
      }
    };

    fetchPaymentInfo();
  }, [csrfToken]);

  const handlePaymentMethodSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (!stripe || !elements) {
        setMessage("Stripe is not initialized.");
        setLoading(false);
        return;
      }

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url:
            "https://memorydiaries.com/lifestripe/update-payment-method",
        },
      });

      if (error) {
        setMessage(error.message);
        setLoading(false);
        return;
      }

      const response = await axiosInstance.post(
        "https://memorydiaries.com/lifestripe/update-payment-method",
        {
          customerId,
          paymentMethodId:
            elements.getElement(PaymentElement)?.paymentMethod?.id,
        },
        {
          headers: { "csrf-token": csrfToken },
          withCredentials: true,
        }
      );

      setMessage(
        response.data.message || "Payment method updated successfully!"
      );
    } catch (err) {
      setMessage(err.response?.data?.error || "Error updating payment method.");
    } finally {
      setLoading(false);
    }
  };

  const handleCancelSubscriptionSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axiosInstance.post(
        "https://memorydiaries.com/lifestripe/cancel-subscription",
        {
          subscriptionId,
        },
        {
          headers: { "csrf-token": csrfToken },
          withCredentials: true,
        }
      );

      setMessage(
        response.data.message || "Subscription canceled successfully!"
      );
    } catch (err) {
      setMessage(err.response?.data?.error || "Error canceling subscription.");
    } finally {
      setLoading(false);
    }
  };

  const handlePlanChangeSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axiosInstance.post(
        "https://memorydiaries.com/lifestripe/change-plan",
        {
          subscriptionId,
          planId: selectedPlan,
        },
        {
          headers: { "csrf-token": csrfToken },
          withCredentials: true,
        }
      );

      setMessage(response.data.message || "Plan changed successfully!");
    } catch (err) {
      setMessage(err.response?.data?.error || "Error changing plan.");
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate("/profile");
  };

  const buttonStyle = {
    fontSize: "18px",
    padding: "10px 20px",
    color: "white",
    backgroundColor: "#E29578",
    border: "none",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  };

  const buttonHoverStyle = {
    backgroundColor: "#FFDDD2",
  };

  const availablePlans = [
    { id: "price_1PsGHWRwRACGWZcEPootbVTh", name: "Free Plan", amount: 0 },
    {
      id: "price_1PsGGaRwRACGWZcEKcXrkvFk",
      name: "Six-Monthly Subscription",
      amount: 159,
    },
    {
      id: "price_1PsGIDRwRACGWZcEdPmVC9H9",
      name: "Yearly Subscription",
      amount: 300,
    },
  ];

  return (
    <div className="payment-page">
      <h2>Update Payment Method</h2>
      <form onSubmit={handlePaymentMethodSubmit}>
        <label htmlFor="customerId">Customer ID</label>
        <input id="customerId" type="text" value={customerId} readOnly />
        <label>Card Details</label>
        <div
          id="payment-element"
          ref={paymentElementRef}
          style={{ padding: 20, borderWidth: 1, borderRadius: 10 }}
        >
          <PaymentElement />
        </div>
        <button type="submit" disabled={loading || !stripe}>
          {loading ? "Updating..." : "Update Payment Method"}
        </button>
      </form>

      <h2>Change Subscription Plan</h2>
      <form onSubmit={handlePlanChangeSubmit}>
        <label htmlFor="plans">Available Plans</label>
        <select
          id="plans"
          value={selectedPlan}
          onChange={(e) => setSelectedPlan(e.target.value)}
          required
        >
          <option value="">Select a plan</option>
          {availablePlans.map((plan) => (
            <option key={plan.id} value={plan.id}>
              {plan.name} - ${plan.amount / 100}/month
            </option>
          ))}
        </select>
        <button type="submit" disabled={loading}>
          {loading ? "Changing Plan..." : "Change Plan"}
        </button>
      </form>

      <h2>Cancel Subscription</h2>
      <form onSubmit={handleCancelSubscriptionSubmit}>
        <label htmlFor="subscriptionId">Subscription ID</label>
        <input
          id="subscriptionId"
          type="text"
          value={subscriptionId}
          readOnly
        />
        <button type="submit" disabled={loading}>
          {loading ? "Canceling..." : "Cancel Subscription"}
        </button>
      </form>

      {message && <div className="message">{message}</div>}
      <button
        onClick={handleCancel}
        className="cancel-button"
        style={buttonStyle}
        onMouseOver={(e) =>
          (e.currentTarget.style.backgroundColor =
            buttonHoverStyle.backgroundColor)
        }
        onMouseOut={(e) =>
          (e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor)
        }
        onFocus={(e) =>
          (e.currentTarget.style.backgroundColor =
            buttonHoverStyle.backgroundColor)
        }
        onBlur={(e) =>
          (e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor)
        }
      >
        Cancel
      </button>
    </div>
  );
};

export default UpdatePayment;
