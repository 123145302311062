// store/MyChildhoodSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { fetchBackendData } from "./fetchBackendData";

const initialState = {
  childhoodStories: [],
};

const myChildhoodSlice = createSlice({
  name: "myChildhood",
  initialState,
  reducers: {
    addStory: (state, action) => {
      state.childhoodStories.push({ text: action.payload });
    },
    editStory: (state, action) => {
      const { index, text } = action.payload;
      state.childhoodStories[index].text = text;
    },
    deleteStory: (state, action) => {
      state.childhoodStories.splice(action.payload, 1);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBackendData.fulfilled, (state, action) => {
      state.childhoodStories = action.payload?.state?.childhoodStories || [];
    });
  },
});

export const { addStory, editStory, deleteStory } = myChildhoodSlice.actions;

export default myChildhoodSlice.reducer;
