import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateDetails, clearError } from "../store/authSlice";
import safeRegex from "safe-regex";
import DOMPurify from "dompurify";
import imageCompression from "browser-image-compression";
import axiosInstance from "./axiosConfig";

const phoneRegex = /^[0-9]{10,15}$/;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

function EditProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, loading, error } = useSelector((state) => state.auth);

  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || "");
  const [email, setEmail] = useState(user?.email || "");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [profileImage, setProfileImage] = useState(user?.profileImage || "");
  const [address, setAddress] = useState(user?.address || "");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const { csrfToken } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user) {
      setPhoneNumber(user.phoneNumber || "");
      setEmail(user.email || "");
      setProfileImage(user.profileImage || "");
      setAddress(user.address || "");
    }
  }, [user]);

  useEffect(() => {
    getProfilePhoto();
  }, []);

  const getProfilePhoto = async () => {
    try {
      // Assuming the token is stored in localStorage
      const response = await axiosInstance.get(
        "https://memorydiaries.com/lifeauth/profile-get",
        {
          method: "GET",
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        const result = response.data;
        console.log("Profile image:", result);
        setProfileImage(URL.createObjectURL(result)); // Update profile image preview
      } else {
        const error = await response.json();
        console.error("Get image error:", error);
        alert("Failed to get image");
      }
    } catch (error) {
      console.error("Error getting the image:", error);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await axiosInstance.delete(
        "https://memorydiaries.com/lifeauth/profile-delete",
        {
          method: "DELETE",
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setProfileImage(null); // Clear the profile image from state
        console.log("Image deleted successfully");
      } else {
        const error = await response.json();
        console.error("Delete error:", error);
        alert("Failed to delete image");
      }
    } catch (error) {
      console.error("Error deleting the image:", error);
      alert("Error deleting the image");
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];

    const supportedFormats = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/tiff",
      "image/bmp",
      "image/webp",
      "image/svg+xml",
      "image/jpg",
    ];

    if (!file) {
      alert("No file selected.");
      return;
    }
    if (!supportedFormats.includes(file.type)) {
      setErrorMessage(
        "Supported formats are: JPG, JPEG, PNG, GIF, TIFF, BMP, WebP, SVG"
      );
      return;
    }
    setErrorMessage("");

    const options = {
      maxWidthOrHeight: 400,
      useWebWorker: true,
      fileType: "image/jpg",
    };
    try {
      const compressedFile = await imageCompression(file, options);
      const renamedFile = new File([compressedFile], file.name, {
        type: compressedFile.type,
        lastModified: Date.now(),
      });
      console.log("Compressed file:", renamedFile);
      setSelectedFile(renamedFile);

      // Upload file to the server
      const formData = new FormData();
      formData.append("file", renamedFile);
      const response = await axiosInstance.post(
        "https://memorydiaries.com/lifeauth/upload-profile-image",
        formData,
        {
          method: "POST",
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        const result = response.data;
        console.log("Upload response:", result);
        setProfileImage(URL.createObjectURL(renamedFile)); // Update profile image preview
      } else {
        const error = await response.json();
        console.error("Upload error:", error);
        setErrorMessage("Failed to upload image");
      }
    } catch (error) {
      console.error("Error compressing the image:", error);
      setErrorMessage("Error processing the image");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      phoneNumber &&
      (!safeRegex(phoneRegex) || !phoneRegex.test(phoneNumber))
    ) {
      alert(
        "Invalid phone number. Please enter a valid number with 10 to 15 digits."
      );
      return;
    }

    if (email && (!safeRegex(emailRegex) || !emailRegex.test(email))) {
      alert("Invalid email address.");
      return;
    }

    if (
      newPassword &&
      (!safeRegex(passwordRegex) || !passwordRegex.test(newPassword))
    ) {
      alert(
        "New password must be at least 8 characters long and include at least one letter and one number."
      );
      return;
    }

    if (newPassword && newPassword !== confirmNewPassword) {
      alert("New password and confirm password do not match.");
      return;
    }

    const sanitizedPhoneNumber = DOMPurify.sanitize(phoneNumber);
    const sanitizedEmail = DOMPurify.sanitize(email);
    const sanitizedOldPassword = DOMPurify.sanitize(oldPassword);
    const sanitizedNewPassword = DOMPurify.sanitize(newPassword);
    const sanitizedAddress = DOMPurify.sanitize(address);

    const updateData = {};
    if (sanitizedPhoneNumber) updateData.phoneNumber = sanitizedPhoneNumber;
    if (sanitizedEmail) updateData.email = sanitizedEmail;
    if (sanitizedNewPassword) updateData.newPassword = sanitizedNewPassword;
    if (sanitizedOldPassword) updateData.currentPassword = sanitizedOldPassword;
    if (sanitizedAddress) updateData.address = sanitizedAddress;

    dispatch(updateDetails(updateData)).then((result) => {
      if (result.meta.requestStatus === "fulfilled") {
        navigate("/profile");
      }
    });
  };

  const handleCancel = () => {
    navigate("/profile"); // or navigate to the profile page or the previous page
  };

  return (
    <div
      style={{
        padding: "20px",
        maxWidth: "600px",
        margin: "auto",
        fontSize: "18px",
      }}
    >
      <h2 style={{ textAlign: "center" }}>Edit Profile</h2>
      <form
        onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column", gap: "15px" }}
      >
        <div>
          <label>Phone Number:</label>
          <input
            type="tel"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(DOMPurify.sanitize(e.target.value))}
            style={{ fontSize: "16px", padding: "8px", width: "100%" }}
          />
        </div>
        <div>
          <label>Email Address:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(DOMPurify.sanitize(e.target.value))}
            style={{ fontSize: "16px", padding: "8px", width: "100%" }}
            required
          />
        </div>
        <div>
          <label>Old Password (Optional):</label>
          <input
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(DOMPurify.sanitize(e.target.value))}
            style={{ fontSize: "16px", padding: "8px", width: "100%" }}
          />
        </div>
        <div>
          <label>New Password (Optional):</label>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(DOMPurify.sanitize(e.target.value))}
            style={{ fontSize: "16px", padding: "8px", width: "100%" }}
          />
        </div>
        <div>
          <label>Confirm New Password (Optional):</label>
          <input
            type="password"
            value={confirmNewPassword}
            onChange={(e) =>
              setConfirmNewPassword(DOMPurify.sanitize(e.target.value))
            }
            style={{ fontSize: "16px", padding: "8px", width: "100%" }}
          />
        </div>
        <div>
          <label>Profile Image:</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            style={{ fontSize: "16px", padding: "8px", width: "100%" }}
          />
          {profileImage && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={profileImage}
                alt="Profile"
                style={{
                  marginTop: "10px",
                  width: "100px",
                  height: "100px",
                  objectFit: "cover",
                }}
              />
              <button
                onClick={handleDelete}
                style={{
                  padding: "5px 10px",
                  border: "none",
                  backgroundColor: "#f44336",
                  color: "#fff",
                  cursor: "pointer",
                  borderRadius: "5px",
                }}
              >
                Delete
              </button>
            </div>
          )}
        </div>
        <div>
          <label>Postal Address:</label>
          <textarea
            value={address}
            onChange={(e) => setAddress(DOMPurify.sanitize(e.target.value))}
            style={{
              fontSize: "16px",
              padding: "8px",
              width: "100%",
              height: "100px",
            }}
          />
        </div>
        {error && (
          <div>
            <p>{error}</p>
            <button type="button" onClick={() => dispatch(clearError())}>
              Clear Error
            </button>
          </div>
        )}
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <button
            type="submit"
            style={{ fontSize: "18px", padding: "10px 20px" }}
            disabled={loading}
          >
            {loading ? "Saving..." : "Save"}
          </button>
          <button
            type="button"
            onClick={handleCancel}
            style={{ fontSize: "18px", padding: "10px 20px" }}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditProfile;
