// src/store/cloudObjectSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../components/axiosConfig";
import { fetchBackendData } from "./fetchBackendData";

const initialState = {
  photoDescriptions: [],
  loading: false,
  error: null,
};

export const savePhoto = createAsyncThunk(
  "photos/savePhoto",
  async (file, { rejectWithValue, getState }) => {
    const { csrfToken } = getState().auth;
    try {
      console.log("Tryign to send file", file);
      const response = await axiosInstance.post(
        "https://memorydiaries.com/lifeData/photos/upload",
        file,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );
      console.log("file sent successfully");
      return response.data;
    } catch (error) {
      console.log("Error sending file", error);
      return rejectWithValue(error.response.data || error.message);
    }
  }
);

export const deletePhoto = createAsyncThunk(
  "photos/deletePhoto",
  async (fileKey, { rejectWithValue, getState }) => {
    const { csrfToken } = getState().auth;
    try {
      const response = await axiosInstance.delete(
        `https://memorydiaries.com/lifeData/photos/delete/${fileKey}`,
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || error.message);
    }
  }
);

const MyPhotoSlice = createSlice({
  name: "photos",
  initialState,
  reducers: {
    editDescription: (state, action) => {
      const { key, text } = action.payload; // key should be the video Key, not index
      const description = state.photoDescriptions.find(
        (desc) => desc.Key === key
      );
      if (description) {
        description.text = text; // Update existing description
      } else {
        state.photoDescriptions.push({ Key: key, text }); // Add new description
      }
    },
    deleteDescription: (state, action) => {
      state.photoDescriptions.splice(action.payload, 1);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(savePhoto.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(savePhoto.fulfilled, (state, action) => {
        state.loading = false;
        const existingPhoto = state.photos.find(
          (photo) => photo.Key === action.payload.Key
        );
        if (!existingPhoto) {
          state.photos.push(action.payload);
        } else {
          alert("Photo name already exists");
        }
      })
      .addCase(savePhoto.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deletePhoto.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deletePhoto.fulfilled, (state, action) => {
        state.loading = false;
        state.photos = state.photos.filter(
          (pho) => pho.Key !== action.payload.Key
        );
      })
      .addCase(deletePhoto.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchBackendData.fulfilled, (state, action) => {
        state.photoDescriptions =
          action.payload?.state?.photoDescriptions || [];
        console.log("Fetched photoDescriptions:", state.photoDescriptions);
      });
  },
});

export const { editDescription, deleteDescription } = MyPhotoSlice.actions;
export default MyPhotoSlice.reducer;
