import React from "react";
import { useSelector } from "react-redux";
import { Container, Typography, Box } from "@mui/material";

const StateDataDisplay = () => {
  const state = useSelector((state) => state);

  // Debugging log to verify state
  console.log("Current Redux State:", state);

  // Access all environment variables
  const envVariables = process.env;

  return (
    <Container style={{ marginTop: 20 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Redux State Data and Environment Variables
      </Typography>
      {state ? (
        <Box
          component="pre"
          style={{
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            backgroundColor: "#f5f5f5",
            padding: 10,
            borderRadius: 4,
            border: "1px solid #ccc",
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Redux State:
          </Typography>
          {JSON.stringify(state, null, 2)}
          <Typography variant="h6" component="h2" gutterBottom>
            Environment Variables:
          </Typography>
          {JSON.stringify(envVariables, null, 2)}
        </Box>
      ) : (
        <Typography variant="body1">No state data available</Typography>
      )}
      <Box
        component="pre"
        style={{
          marginTop: 20,
          whiteSpace: "pre-wrap",
          wordWrap: "break-word",
          backgroundColor: "#f5f5f5",
          padding: 10,
          borderRadius: 4,
          border: "1px solid #ccc",
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Environment Variables:
        </Typography>
        {JSON.stringify(envVariables, null, 2)}
      </Box>
    </Container>
  );
};

export default StateDataDisplay;
