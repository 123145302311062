import React, { useEffect, useState } from "react";
import {
  Typography,
  Container,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import { useSelector } from "react-redux";
import DownloadIcon from "@mui/icons-material/Download";
import axiosInstance from "../axiosConfig";

const FamilyDocuments = () => {
  const [documents, setDocuments] = useState([]);
  const loading = useSelector((state) => state.myDocuments.loading);
  const error = useSelector((state) => state.myDocuments.error);

  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [descriptions, setDescriptions] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const { csrfToken } = useSelector((state) => state.auth);

  useEffect(() => {
    // Fetch photos from the backend
    const fetchDocuments = async () => {
      try {
        const response = await axiosInstance.get(
          "https://memorydiaries.com/lifeData/documents/download",
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "csrf-token": csrfToken,
            },
            withCredentials: true,
          }
        );
        console.log("Response data:", response.data); // Log the response data
        setDocuments(response.data);
        if (Array.isArray(response.data)) {
          setDescriptions(response.data.map(() => "")); // Initialize descriptions
        } else {
          console.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching photos:", error);
      }
    };

    fetchDocuments();
    setRefresh(false);
  }, [refresh]);

  const handleDownload = (docKey) => {
    const document = documents.find((doc) => doc.Key === docKey);
    if (document) {
      const fileUrl = document.fileUrl; // Assuming you have a URL from the backend
      if (fileUrl) {
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", document.name || document.Key);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        console.error("No valid file URL found for download.");
      }
    }
  };

  return (
    <Container style={{ marginTop: 20 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Family Documents
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        Manage Family Documents
      </Typography>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" component="h2" gutterBottom>
          Uploaded Documents
        </Typography>
        {documents.length > 0 ? (
          <List>
            {documents.map((doc, index) => (
              <ListItem
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <ListItemText primary={doc.name || doc.Key} />
                <IconButton
                  aria-label="download"
                  onClick={() => handleDownload(doc.name)}
                >
                  <DownloadIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="body1">No documents uploaded yet.</Typography>
        )}
      </Box>
    </Container>
  );
};

export default FamilyDocuments;
