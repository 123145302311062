import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Button,
  Typography,
  Container,
  Box,
  IconButton,
} from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import safeRegex from "safe-regex";
import DOMPurify from "dompurify";
import { addStory, editStory, deleteStory } from "../store/MyAdulthoodSlice";

const descriptionRegex = /^[a-zA-Z0-9\s.,!?]*$/;

const MyAdulthood = () => {
  const [story, setStory] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [isEditing, setIsEditing] = useState(null);
  const recognitionRef = useRef(null);

  const dispatch = useDispatch();
  const stories = useSelector((state) => state.myAdulthood.adultStories);

  const handleChange = (event) => {
    const inputValue = event.target.value;

    if (!safeRegex(descriptionRegex)) {
      console.error("Unsafe regular expression detected!");
      return;
    }

    if (!descriptionRegex.test(inputValue)) {
      console.error("Invalid characters in story.");
      return;
    }

    const sanitizedStory = DOMPurify.sanitize(inputValue);
    setStory(sanitizedStory);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (story.trim()) {
      if (isEditing !== null) {
        dispatch(editStory({ index: isEditing, text: story }));
        setIsEditing(null);
      } else {
        dispatch(addStory(story));
      }
      setStory("");
    }
  };

  const handleEdit = (index) => {
    setStory(stories[index].text);
    setIsEditing(index);
  };

  const handleDelete = (index) => {
    dispatch(deleteStory(index));
  };

  const handleSpeechRecognition = () => {
    if (!("webkitSpeechRecognition" in window)) {
      alert(
        "Speech recognition not supported in this browser. Please use Chrome."
      );
      return;
    }

    if (!recognitionRef.current) {
      const recognition = new window.webkitSpeechRecognition();
      recognition.continuous = false;
      recognition.interimResults = false;
      recognition.lang = "en-US";

      recognition.onstart = () => {
        setIsRecording(true);
      };

      recognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript;

        if (safeRegex(descriptionRegex) && descriptionRegex.test(transcript)) {
          const sanitizedTranscript = DOMPurify.sanitize(transcript);
          setStory((prevStory) => `${prevStory} ${sanitizedTranscript}`);
        } else {
          console.error("Invalid characters detected in speech transcript.");
        }
      };

      recognition.onerror = (event) => {
        console.error("Speech recognition error", event);
        setIsRecording(false);
      };

      recognition.onend = () => {
        setIsRecording(false);
        // Automatically submit the story after speech recognition ends
        if (story.trim()) {
          if (isEditing !== null) {
            dispatch(editStory({ index: isEditing, text: story }));
            setIsEditing(null);
          } else {
            dispatch(addStory(story));
          }
          setStory("");
        }
      };

      recognitionRef.current = recognition;
    }

    if (isRecording) {
      recognitionRef.current.stop();
    } else {
      recognitionRef.current.start();
    }
  };

  return (
    <Container style={{ marginTop: 20 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Record Your Adulthood Stories
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          id="story"
          label="Your Adulthood Story"
          multiline
          rows={6}
          variant="outlined"
          fullWidth
          value={story}
          onChange={handleChange}
          margin="normal"
        />
        <IconButton
          color="default"
          onClick={handleSpeechRecognition}
          sx={{ color: isRecording ? "red" : "inherit" }}
          aria-label="record story"
        >
          <MicIcon />
        </IconButton>
        <Button
          type="submit"
          variant="contained"
          sx={{
            backgroundColor: "#E29578",
            "&:hover": {
              backgroundColor: "#FFDDD2",
            },
          }}
        >
          Submit
        </Button>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" component="h2" gutterBottom>
          Your Recorded Stories
        </Typography>
        {stories.length > 0 ? (
          stories.map((s, index) => (
            <Box
              key={index}
              sx={{
                mb: 2,
                p: 2,
                border: "1px solid #ccc",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="body1" sx={{ flexGrow: 1 }}>
                {s.text}
              </Typography>
              <IconButton
                aria-label="edit"
                onClick={() => handleEdit(index)}
                sx={{ marginRight: 1 }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                aria-label="delete"
                onClick={() => handleDelete(index)}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          ))
        ) : (
          <Typography variant="body1">No stories recorded yet.</Typography>
        )}
      </Box>
    </Container>
  );
};

export default MyAdulthood;
