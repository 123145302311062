// src/store/MyAchievementsSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { fetchBackendData } from "./fetchBackendData";

const initialState = {
  achievements: [],
};

const myAchievementsSlice = createSlice({
  name: "myAchievements",
  initialState,
  reducers: {
    addAchievement: (state, action) => {
      state.achievements.push({ text: action.payload });
    },
    editAchievement: (state, action) => {
      const { index, text } = action.payload;
      state.achievements[index].text = text;
    },
    deleteAchievement: (state, action) => {
      state.achievements.splice(action.payload, 1);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBackendData.fulfilled, (state, action) => {
      state.achievements = action.payload?.state?.achievements || [];
    });
  },
});

export const { addAchievement, editAchievement, deleteAchievement } =
  myAchievementsSlice.actions;

export default myAchievementsSlice.reducer;
