import React from "react";

function Footer() {
  return (
    <footer style={footerStyle}>
      <div style={contentStyle}>
        <p>ABN: 15 776 628 622</p>
      </div>
      <div style={contentStyleTwo}>
        <p style={linkStyle}>Email: dr.dbrown@icloud.com</p>
        <p>&copy; 2024</p>
      </div>
    </footer>
  );
}

//  background: "linear-gradient(to right, #93CE9B, #69A06D)",
const footerStyle = {
  display: "flex",
  background: "linear-gradient(90deg, #83C5BE, #6FA7A2, #5C8A85)",
  textAlign: "center",
  bottom: "0",
  width: "100%",
  borderTop: "1px solid #e7e7e7",
  position: "fixed",
  left: "0",
  right: "0",
  height: "80px",
  flexDirection: "row",
  justifyContent: "space-between",
};

const contentStyle = {
  display: "flex",
  flexDirection: "column",
  marginLeft: 20,
  fontFamily: "Verdana, sans-serif",
  fontSize: "14px",
  alignItems: "flex-start",
  color: "white",
};

const contentStyleTwo = {
  display: "flex",
  flexDirection: "column",
  marginRight: 20,
  fontFamily: "Verdana, sans-serif",
  fontSize: "14px",
  alignItems: "flex-end",
  color: "white",
};

const linkStyle = {
  color: "white",
  textDecoration: "none",
};

export default Footer;
