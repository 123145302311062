// store/index.js
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import saveFormDataMiddleware from "../middleware/saveFormDataMiddleware";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas";

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// Configure the store with the root reducer, saga, and custom middleware
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializable checks if needed
    }).concat(sagaMiddleware, saveFormDataMiddleware), // Add saga and saveFormData middleware
});

// Run the root saga
sagaMiddleware.run(rootSaga);

export default store;
