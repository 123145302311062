import axios from "axios";

const privateIpRanges = [/^10\./, /^192\.168\./, /^127\./, /^169\.254\./];

const isPrivateIp = (ip) => privateIpRanges.some((range) => range.test(ip));

const axiosInstance = axios.create({
  maxRedirects: 0,
  timeout: 3000000,
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const url = new URL(config.url);
    const ipAddress = url.hostname;

    console.log("Requesting URL:", config.url);
    console.log("Resolved IP Address:", ipAddress);
    console.log("Axios Config:", config);

    if (isPrivateIp(ipAddress) && !ipAddress.startsWith("172.21.")) {
      console.error("Blocked request to private IP:", ipAddress);
      return Promise.reject(
        new Error("Request blocked: Private IP addresses are not allowed")
      );
    }

    return config;
  },
  (error) => {
    console.error("Error in request interceptor:", error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    console.log("Response received:", response);
    return response;
  },
  (error) => {
    console.error("Axios network error:", error.message);
    console.error(
      "Axios error details:",
      error.response ? error.response.data : "No response data"
    );
    console.error("Axios error stack:", error.stack); // Log the stack trace for debugging
    console.error("Axios error config:", error.config); // Log the request config for debugging

    if (error.code === "ECONNABORTED") {
      console.error(
        "The request timed out. Check the server response time or increase the timeout."
      );
    }

    return Promise.reject(error); // Continue to propagate the error
  }
);

export default axiosInstance;
