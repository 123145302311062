import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../components/axiosConfig";

// will be needed to update the subscriptionId in the state
/* const updateSubscriptionId = (subscriptionId) => ({
  type: "stripe/updateSubscriptionId",
  payload: subscriptionId,
}); */

// Async thunk for creating a customer and subscription
export const createCustomerAndSubscription = createAsyncThunk(
  "stripe/createCustomerAndSubscription",
  async ({ email, address, plan_id, currency }, thunkAPI) => {
    const { csrfToken } = thunkAPI.getState().auth;
    try {
      const response = await axiosInstance.post(
        "https://memorydiaries.com/lifestripe/create-customer",
        {
          email,
          address,
          plan_id,
          currency,
        },
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );

      if (response.status !== 200) {
        return thunkAPI.rejectWithValue(
          response.data.error || "Failed to create customer and subscription"
        );
      }

      alert(
        "Customer and subscription created successfully by createCustomerAndSubscription!"
      );
      return response.data; // Contains subscriptionId and clientSecret
    } catch (error) {
      if (error.response && error.response.data) {
        // Server responded with an error
        alert("Failed to create customer and subscription");
        return thunkAPI.rejectWithValue(
          error.response.data.error || "Server error"
        );
      } else {
        // Network error or other issues
        alert("Failed to create customer and subscription");
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const checkPaymentStatus = createAsyncThunk(
  "stripe/checkPaymentStatus",
  async (_, thunkAPI) => {
    console.log("Checking payment status...");
    const { csrfToken } = thunkAPI.getState().auth;
    try {
      const response = await axiosInstance.get(
        `https://memorydiaries.com/lifestripe/check-payment-status`,
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );
      console.log("Check payment response status:", response.status);
      if (response.status !== 200) {
        return thunkAPI.rejectWithValue(
          console.log("Failed to check payment status"),
          response.data.error || "Failed to check payment status"
        );
      }
      console.log("Payment status checked successfully!", response.data);
      return response.data; // Returns true or false
    } catch (error) {
      if (error.response && error.response.data) {
        console.log("Failed to check payment status");
        return thunkAPI.rejectWithValue(
          error.response.data.error || "Server error"
        );
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// Thunk to fetch clientSecret from the backend
export const fetchClientSecret = createAsyncThunk(
  "stripe/fetchClientSecret",
  async (email, { rejectWithValue, getState }) => {
    const { csrfToken } = getState().auth;
    try {
      const response = await axiosInstance.post(
        `https://memorydiaries.com/lifestripe/create-setup-intent`,
        { email },
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );
      console.log(
        "Client secret fetched successfully! response.data.clientSecret",
        response.data.clientSecret
      );
      return response.data.clientSecret;
    } catch (error) {
      console.log("Failed to fetch clientSecret");
      return rejectWithValue(
        error.response?.data?.error || "Failed to fetch clientSecret"
      );
    }
  }
);

const stripeSlice = createSlice({
  name: "stripe",
  initialState: {
    subscriptionId: null,
    clientSecret: null,
    loading: false,
    error: null,
    isPaid: null,
  },
  reducers: {
    updateSubscriptionId: (state, action) => {
      state.subscriptionId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCustomerAndSubscription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCustomerAndSubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.subscriptionId = action.payload.subscriptionId;
        state.clientSecret = action.payload.clientSecret;
        state.isPaid = action.payload.isPaid;
        console.log(
          "Customer and subscription fulfilled! isPaid:",
          state.isPaid
        );
      })
      .addCase(createCustomerAndSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Something went wrong";
        console.log("Failed to create customer and subscription");
      })
      .addCase(checkPaymentStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(checkPaymentStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.isPaid = action.payload.isPaid;
        state.paymentPlan = action.payload.planCode;
        console.log("Payment status checked successfully!", action.payload);
      })
      .addCase(checkPaymentStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to check payment status";
        console.log("Failed to check payment status");
      })
      .addCase(fetchClientSecret.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchClientSecret.fulfilled, (state, action) => {
        state.loading = false;
        state.clientSecret = action.payload;
        console.log("Fulfilled client secret:", action.payload);
      })
      .addCase(fetchClientSecret.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const selectStripeState = (state) => state.stripe;
// export const { updateSubscriptionId } = stripeSlice.actions;

export default stripeSlice.reducer;
