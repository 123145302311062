import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const FamilyNavigationDrawer = () => {
  const [open, setOpen] = useState(false);
  const state = useSelector((state) => state.auth);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  const list = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {[
          "Home",
          `${state.user.name}'s Childhood`,
          `${state.user.name}'s Adolescence`,
          `${state.user.name}'s Adulthood`,
          `${state.user.name}'s Achievements`,
          `${state.user.name}'s Documents`,
          `${state.user.name}'s Photos`,
          `${state.user.name}'s Videos`,
          "Contact Us",
        ].map((text) => {
          let route = "";
          switch (text) {
            case `${state.user.name}'s Childhood`:
              route = "/familychildhood";
              break;
            case `${state.user.name}'s Adolescence`:
              route = "/familyadolescence";
              break;
            case `${state.user.name}'s Adulthood`:
              route = "/familyadulthood";
              break;
            case `${state.user.name}'s Achievements`:
              route = "/familyachievements";
              break;
            case `${state.user.name}'s Documents`:
              route = "/familydocuments";
              break;
            case `${state.user.name}'s Photos`:
              route = "/familyphotos";
              break;
            case `${state.user.name}'s Videos`:
              route = "/familyvideos";
              break;
            case "Contact Us":
              route = "/contactus";
              break;
            default:
              route = `/${text.replace(" ", "").toLowerCase()}`;
          }

          return (
            <ListItem key={text} component={Link} to={route}>
              <ListItemText primary={text} />
            </ListItem>
          );
        })}
      </List>
    </div>
  );

  return (
    <div>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </div>
  );
};

export default FamilyNavigationDrawer;
