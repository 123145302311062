import React from "react";
import Tree from "../Img/image-4.webp";
import Footer from "./Footer";
import { Typography } from "@mui/material";
import { useSpring, animated } from "@react-spring/web";

function AnimatedTypography({ children, ...props }) {
  const animationProps = useSpring({
    from: { opacity: 0, transform: "translate3d(0,40px,0)" },
    to: { opacity: 1, transform: "translate3d(0,0px,0)" },
    config: { tension: 200, friction: 15 },
  });

  return (
    <animated.div style={animationProps}>
      <Typography {...props}>{children}</Typography>
    </animated.div>
  );
}

const Welcome = () => {
  return (
    <div>
      <AnimatedTypography
        variant="h2"
        paragrah
        style={{
          marginBottom: 50,
          fontWeight: "bold",
        }}
      >
        WEBSITE UNDER CONSTRUCTION
      </AnimatedTypography>
      <div style={{ display: "flex", flexDirection: "row", marginBottom: 100 }}>
        <div style={{ flexDirection: "column", marginRight: "5%" }}>
          <AnimatedTypography
            variant="h5"
            paragraph
            style={{
              fontStyle: "italic",
              marginBottom: 50,
              fontWeight: "bold",
            }}
          >
            Welcome to Memory Diaries
          </AnimatedTypography>
          <AnimatedTypography variant="h6" paragraph>
            Memory Diaries is a platform where you can document and share your
            life events with family members. Share your life’s greatest stories
            and wisdom with the ones you love, creating a treasure trove of
            memories for generations to come. You can create personal entries,
            upload photos and videos, to record memorable moments, ensuring that
            these cherished memories are preserved for future generations.{" "}
          </AnimatedTypography>
          <AnimatedTypography variant="h6" paragraph>
            The platform prioritizes security and privacy, implementing advanced
            robust encryption and authentication measures to safeguard user
            data. We employ current industry world-leading cybersecurity best
            practice. You can confidently share your experiences and milestones,
            knowing that you information is protected and accessible only by you
            and selected family members.
          </AnimatedTypography>
        </div>
        <div>
          <img
            src={Tree}
            alt="Tree of Life"
            style={{
              width: "45vw",
              resize: "contain",
              marginLeft: "5%",
              marginRight: "5%",
              marginTop: "2%",
              borderRadius: 30,
            }}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Welcome;
