import React, { useEffect, useState } from "react";
import {
  Typography,
  Container,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  IconButton,
  ListItemText,
} from "@mui/material";
import { useSelector } from "react-redux";
import DownloadIcon from "@mui/icons-material/Download";
import axiosInstance from "../axiosConfig";

const FamilyPhotos = () => {
  const [refresh, setRefresh] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [descriptions, setDescriptions] = useState([]);
  const { csrfToken } = useSelector((state) => state.auth);

  useEffect(() => {
    // Fetch photos from the backend
    const fetchPhotos = async () => {
      try {
        const response = await axiosInstance.get(
          "https://memorydiaries.com/lifeData/photos/download",
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "csrf-token": csrfToken,
            },
            withCredentials: true,
          }
        );
        console.log("Response data:", response.data); // Log the response data
        setPhotos(response.data);
        if (Array.isArray(response.data)) {
          setDescriptions(response.data.map(() => "")); // Initialize descriptions
        } else {
          console.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching photos:", error);
      }
    };

    fetchPhotos();
    setRefresh(false);
  }, [refresh]);

  const handleDownload = (photoName) => {
    const photo = photos.find((pho) => pho.Key === photoName);
    if (photo) {
      const blob = new Blob([photo.file], { type: "application/octet-stream" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a"); // Corrected to use document.createElement
      link.href = url;
      link.setAttribute("download", photoName); // Set the download attribute with the photo's name
      document.body.appendChild(link); // Append the link to the body
      link.click(); // Simulate a click to trigger the download
      document.body.removeChild(link); // Remove the link after the download
    }
  };

  return (
    <Container style={{ marginTop: 20 }}>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" component="h2" gutterBottom>
          Family Photos
        </Typography>
        {photos.length > 0 ? (
          <List>
            {photos.map((photo, index) => (
              <ListItem
                key={index}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <ListItemAvatar>
                  <Avatar
                    src={`data:${photo.contentType};base64,${photo.base64}`}
                    alt={`Photo ${index + 1}`}
                    variant="square"
                    sx={{
                      width: "300px",
                      height: "300px",
                      objectFit: "contain",
                    }}
                  />
                </ListItemAvatar>
                <ListItemText primary={`Photo ${index + 1}`} />
                <>
                  <Typography variant="body1">{descriptions[index]}</Typography>
                </>
                <IconButton
                  edge="end"
                  aria-label="download"
                  onClick={() => handleDownload(photo.Key)}
                  sx={{ marginLeft: "16px" }}
                >
                  <DownloadIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="body1">No photos available.</Typography>
        )}
      </Box>
    </Container>
  );
};

export default FamilyPhotos;
