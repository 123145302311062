import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Placeholder from "../Img/ProfilePlaceholder.jpg";
import { deleteUser } from "../store/authSlice";
import axiosInstance from "./axiosConfig";

function Profile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const state = useSelector((state) => state);
  const [profileImage, setProfileImage] = useState(user?.profileImage || "");
  const { csrfToken } = useSelector((state) => state.auth);
  const { paymentPlan } = useSelector((state) => state.stripe);
  const handleEdit = () => {
    navigate("/edit-profile"); // Adjust this path as needed
  };

  useEffect(() => {
    getProfilePhoto();
  }, []);

  const getProfilePhoto = async () => {
    try {
      const response = await axiosInstance.get(
        "https://memorydiaries.com/lifeauth/profile-get",
        {
          headers: {
            "csrf-token": csrfToken,
          },
        }
      );

      if (response.status === 200) {
        const result = response.data[0];
        console.log("Profile image result:", result);
        const base64ImageUrl = `data:${result.contentType};base64,${result.base64}`;
        setProfileImage(base64ImageUrl);
      } else {
        console.error("Failed to get image", response.data);
      }
    } catch (error) {
      console.error("Error fetching profile photo:", error);
    }
  };

  const handleDelete = () => {
    if (
      window.confirm(
        "Are you sure you want to delete your account? This action cannot be undone."
      )
    ) {
      dispatch(deleteUser())
        .then((result) => {
          console.log("Delete user result:", result);
          if (result.meta.requestStatus === "fulfilled") {
            alert("Account deletion succeeded, navigating to home");
            navigate("/"); // Adjust this path as needed
          } else {
            alert("Failed to delete account");
          }
        })
        .catch((error) => {
          console.error("Delete user error:", error); // Log any errors that occur
          alert("An error occurred while trying to delete your account");
        });
    }
  };

  const paymentType = (paymentPlan) => {
    if (paymentPlan === "price_1PsGHWRwRACGWZcEPootbVTh") {
      return "Free PlN";
    } else if (paymentPlan === "price_1PsGGaRwRACGWZcEKcXrkvFk") {
      return "Six Monthly Plan";
    } else if (paymentPlan === "price_1PsGIDRwRACGWZcEdPmVC9H9") {
      return "Yearly Plan";
    } else {
      return "Unknown";
    }
  };

  const handleEditFamily = () => {
    navigate("/edit-family");
  };

  const handleAddFamily = () => {
    navigate("/add-family");
  };

  const handleUpdatePayment = () => {
    navigate("/update-payment");
  };

  const buttonStyle = {
    fontSize: "18px",
    padding: "10px 20px",
    color: "white",
    backgroundColor: "#E29578",
    border: "none",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  };

  const buttonHoverStyle = {
    backgroundColor: "#FFDDD2",
  };

  return (
    <div
      style={{
        padding: "20px",
        maxWidth: "800px",
        margin: "auto",
        fontSize: "18px",
      }}
    >
      <h2 style={{ textAlign: "center" }}>Profile</h2>
      {user && (
        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <label
              htmlFor="userName"
              style={{ fontWeight: "bold", fontSize: "18px", padding: "8px" }}
            >
              User Name:
            </label>
            <div style={{ fontSize: "18px", padding: "8px" }}>{user.name}</div>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <label
              htmlFor="email"
              style={{ fontWeight: "bold", fontSize: "18px", padding: "8px" }}
            >
              Email Address:
            </label>
            <div style={{ fontSize: "18px", padding: "8px" }}>{user.email}</div>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <label
              htmlFor="paymentPlan"
              style={{ fontWeight: "bold", fontSize: "18px", padding: "8px" }}
            >
              Payment Plan:
            </label>
            <div style={{ fontSize: "18px", padding: "8px" }}>
              {paymentType(paymentPlan)}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <label
              htmlFor="phoneNumber"
              style={{ fontWeight: "bold", fontSize: "18px", padding: "8px" }}
            >
              Phone Number:
            </label>
            <div style={{ fontSize: "18px", padding: "8px" }}>
              {user.phoneNumber}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <label
              htmlFor="address"
              style={{ fontWeight: "bold", fontSize: "18px", padding: "8px" }}
            >
              Postal Address:
            </label>
            <div style={{ fontSize: "18px", padding: "8px" }}>
              {user.address}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <label
              htmlFor="address"
              style={{ fontWeight: "bold", fontSize: "18px", padding: "8px" }}
            >
              Code for Family Member Login:
            </label>
            <div style={{ fontSize: "18px", padding: "8px" }}>{user.code}</div>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label
              htmlFor="familyMembers"
              style={{ fontWeight: "bold", fontSize: "18px", padding: "8px" }}
            >
              Authorized Family Members:
            </label>
            <div style={{ fontSize: "18px", padding: "8px" }}>
              {state.family.familyMembers &&
              state.family.familyMembers.length > 0 ? (
                state.family.familyMembers.map((member, index) => (
                  <div key={index} style={{ padding: "4px 0" }}>
                    {member.name}
                  </div>
                ))
              ) : (
                <div>No authorized family members found</div>
              )}
            </div>
          </div>
          <div>
            <label
              htmlFor="profileImage"
              style={{ fontWeight: "bold", fontSize: "18px", padding: "8px" }}
            >
              Profile Image:
            </label>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={profileImage || Placeholder}
                alt="Profile"
                style={{
                  marginTop: "10px",
                  width: "300px",
                  height: "300px",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              />
            </div>
          </div>
          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              onClick={handleEdit}
              style={buttonStyle}
              onMouseOver={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonHoverStyle.backgroundColor)
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonStyle.backgroundColor)
              }
              onFocus={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonHoverStyle.backgroundColor)
              } // Added onFocus
              onBlur={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonStyle.backgroundColor)
              } // Added onBlur
            >
              Edit
            </button>
            <button
              onClick={handleAddFamily}
              style={buttonStyle}
              onMouseOver={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonHoverStyle.backgroundColor)
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonStyle.backgroundColor)
              }
              onFocus={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonHoverStyle.backgroundColor)
              } // Added onFocus
              onBlur={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonStyle.backgroundColor)
              } // Added onBlur
            >
              Add Family Member
            </button>
            <button
              onClick={handleEditFamily}
              style={buttonStyle}
              onMouseOver={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonHoverStyle.backgroundColor)
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonStyle.backgroundColor)
              }
              onFocus={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonHoverStyle.backgroundColor)
              } // Added onFocus
              onBlur={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonStyle.backgroundColor)
              } // Added onBlur
            >
              Edit Family Members
            </button>
            <button
              onClick={handleUpdatePayment}
              style={buttonStyle}
              onMouseOver={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonHoverStyle.backgroundColor)
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonStyle.backgroundColor)
              }
              onFocus={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonHoverStyle.backgroundColor)
              } // Added onFocus
              onBlur={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonStyle.backgroundColor)
              } // Added onBlur
            >
              Update Payment Details
            </button>
            <button
              onClick={handleDelete}
              style={{ ...buttonStyle, color: "white" }}
              onMouseOver={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonHoverStyle.backgroundColor)
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonStyle.backgroundColor)
              }
              onFocus={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonHoverStyle.backgroundColor)
              } // Added onFocus
              onBlur={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonStyle.backgroundColor)
              } // Added onBlur
            >
              Delete Account
            </button>
          </div>{" "}
        </div>
      )}
    </div>
  );
}

export default Profile;
