import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../components/axiosConfig";

// Async thunk for registering a user
export const registerUser = createAsyncThunk(
  "auth/registerUser",
  async ({ email, password, name, code }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `https://memorydiaries.com/lifeauth/register`,
        {
          email,
          password,
          name,
          code,
        },
        {
          withCredentials: true, // Ensure cookies are sent with the request
        }
      );
      console.log("Cookies: ", document.cookie);
      console.log("Register Response Data: ", response.data);
      return response.data;
    } catch (error) {
      console.log("Register Error: ", error);
      const errorMessage =
        error.response?.data?.message || "Registration failed";
      return rejectWithValue(errorMessage);
    }
  }
);

// Async thunk for logging in a user
export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `https://memorydiaries.com/lifeauth/login`,
        {
          email,
          password,
        },
        {
          withCredentials: true, // Ensure cookies are sent with the request
        }
      );

      console.log("Cookies: ", document.cookie);
      console.log("Login Response Data: ", response.data);
      return response.data;
    } catch (error) {
      console.error("Login Error: ", error.message);
      console.error("Error Stack: ", error.stack);
      console.error(
        "Error Response: ",
        error.response ? error.response.data : "No response data"
      );
      const errorMessage = error.response?.data?.message ?? "Login failed";
      return rejectWithValue(errorMessage);
    }
  }
);

// Async thunk for updating user details
export const updateDetails = createAsyncThunk(
  "auth/updateDetails",
  async (userDetails, { rejectWithValue, getState }) => {
    const { csrfToken } = getState().auth;
    try {
      const response = await axiosInstance.post(
        `https://memorydiaries.com/lifeauth/update-details`,
        userDetails,
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );
      console.log("Update Details Response Data: ", response.data);
      return response.data.user;
    } catch (error) {
      console.error("Update Details Error: ", error);
      const errorMessage =
        error.response?.data?.message ?? "Failed to update details";
      return rejectWithValue(errorMessage);
    }
  }
);

// Async thunk for deleting a user
export const deleteUser = createAsyncThunk(
  "auth/deleteUser",
  async (_, { rejectWithValue, getState }) => {
    const { csrfToken } = getState().auth;
    try {
      const response = await axiosInstance.delete(
        `https://memorydiaries.com/lifeauth/delete-user`,
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );
      localStorage.removeItem("token");
      console.log("Delete User Response Data: ", response.data);
      return response.data.message;
    } catch (error) {
      console.error("Delete User Error: ", error);
      const errorMessage =
        error.response?.data?.message ?? "Failed to delete user";
      return rejectWithValue(errorMessage);
    }
  }
);

// Async thunk for logging in a family member
export const loginFamily = createAsyncThunk(
  "auth/loginFamily",
  async ({ code, email }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `https://memorydiaries.com/lifeauth/loginFamilyMember`,
        {
          code,
          email,
        },
        {
          withCredentials: true, // Ensure cookies are sent with the request
        }
      );
      console.log("Cookies: ", document.cookie);
      console.log("Login Family Response Data: ", response.data);
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || "Login failed";
      return rejectWithValue(errorMessage);
    }
  }
);

// Initial state for auth slice
const initialState = {
  user: {
    id: null,
    name: null,
    email: null,
    code: null,
    phoneNumber: null,
    address: null,
  },
  token: localStorage.getItem("token") || null,
  status: "idle",
  loading: false,
  error: null,
};

// Auth slice
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
      localStorage.removeItem("token");
      state.status = "idle";
      state.error = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.csrfToken = action.payload.csrfToken;
        state.user = {
          id: action.payload.id,
          name: action.payload.name,
          email: action.payload.email,
          code: action.payload.code,
          phoneNumber: action.payload.phoneNumber,
          address: action.payload.address,
        };
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(loginFamily.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginFamily.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.csrfToken = action.payload.csrfToken;
        state.user = {
          id: action.payload.id,
          name: action.payload.name,
          email: action.payload.email,
          code: action.payload.code,
          phoneNumber: action.payload.phoneNumber,
          address: action.payload.address,
        };
        state.error = null;
      })
      .addCase(loginFamily.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(registerUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.csrfToken = action.payload.csrfToken;
        state.user = {
          id: action.payload.id,
          name: action.payload.name,
          email: action.payload.email,
          code: action.payload.code,
          phoneNumber: action.payload.phoneNumber,
          address: action.payload.address,
        };
        state.error = null;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(updateDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(updateDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to update details";
      })
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.loading = false;
        state.user = initialState.user;
        state.status = "idle";
        localStorage.removeItem("token");
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to delete user";
      });
  },
});

export const { logout, clearError, resetState } = authSlice.actions;

export default authSlice.reducer;
