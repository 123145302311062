import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../store/authSlice"; // Ensure correct path
import { clearAllData, fetchBackendData } from "../store/fetchBackendData";
import { useNavigate } from "react-router-dom";
import { fetchFamilyMembers } from "../store/FamilySlice"; // Ensure correct path
import safeRegex from "safe-regex";
import DOMPurify from "dompurify";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

function Login({ open, onClose, onLoginSuccess }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState("");
  const dispatch = useDispatch();
  const authStatus = useSelector((state) => state.auth.status);
  const authError = useSelector((state) => state.auth.error);
  const navigate = useNavigate();
  const { csrfToken } = useSelector((state) => state.auth);

  useEffect(() => {
    const handlePostLogin = async () => {
      try {
        const clearDataResult = await dispatch(clearAllData());
        if (!clearAllData.fulfilled.match(clearDataResult)) {
          alert(
            "Failed to clear data:" + JSON.stringify(clearDataResult.payload)
          );
          return;
        }
        console.log("Prior to fetching backend data, csrfToken: ", csrfToken);
        if (csrfToken) {
          try {
            await dispatch(fetchBackendData());
            await dispatch(fetchFamilyMembers());
            console.log("Backend data fetched successfully.");
            alert("Login successful");
          } catch (error) {
            console.error("Fetch backend data error:", error);
          }

          if (userName) {
            onLoginSuccess();
            onClose();
          } else {
            console.error("User name is missing in the login response");
          }
        } else {
          console.error("Login failed, token doesn't exist");
        }
      } catch (error) {
        console.error("Failed to clear data or fetch backend data:", error);
      }
    };

    if (authStatus === "succeeded" && csrfToken) {
      handlePostLogin();
    }
  }, [authStatus, csrfToken, userName, dispatch, onClose, onLoginSuccess]);

  const handleLogin = async () => {
    try {
      const sanitizedEmail = DOMPurify.sanitize(email);
      if (!safeRegex(emailRegex) || !emailRegex.test(sanitizedEmail)) {
        alert("Invalid email format");
        return;
      }

      const sanitizedPassword = DOMPurify.sanitize(password);

      console.log(
        `Dispatching loginUser with email: ${sanitizedEmail}, password: ${sanitizedPassword}`
      );

      const result = await dispatch(
        loginUser({ email: sanitizedEmail, password: sanitizedPassword })
      );

      if (loginUser.fulfilled.match(result)) {
        console.log("Login successful. Updating state.");
        const fetchedName = result.payload.name; // Directly get the name from the payload
        console.log("Fetched name: ", fetchedName);
        setUserName(fetchedName);
      } else {
        console.error("Failed to log in: ", result.error.message);
      }
    } catch (error) {
      console.error("Failed to log in:", error);
    }
  };

  const handleForgotPassword = () => {
    navigate("/reset-password");
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Login</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter your login credentials.
        </DialogContentText>
        {authError && (
          <DialogContentText style={{ color: "red" }}>
            {authError}
          </DialogContentText>
        )}
        <TextField
          autoFocus
          margin="dense"
          label="Email"
          type="email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(DOMPurify.sanitize(e.target.value))}
        />
        <TextField
          margin="dense"
          label="Password"
          type="password"
          fullWidth
          value={password}
          onChange={(e) => setPassword(DOMPurify.sanitize(e.target.value))}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleLogin}
          color="primary"
          disabled={authStatus === "loading"}
        >
          Login
        </Button>
        <Button onClick={handleForgotPassword} color="secondary">
          Forgot Password?
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Login;
