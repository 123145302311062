import { combineReducers } from "redux";
import authSliceReducer from "./authSlice";
import myVideosReducer from "./MyVideoSlice";
import myPhotosReducer from "./MyPhotoSlice";
import myDocumentsReducer from "./MyDocumentSlice";
import familySliceReducer from "./FamilySlice";
import stripeSliceReducer from "./stripeSlice";
import fetchBackendDataReducer from "./fetchBackendData";
import myAchievementsReducer from "./MyAchievementsSlice";
import myAdolescenceReducer from "./MyAdolescenceSlice";
import myChildhoodReducer from "./MyChildhoodSlice";
import myAdulthoodReducer from "./MyAdulthoodSlice";

const appReducer = combineReducers({
  myDocuments: myDocumentsReducer,
  myVideos: myVideosReducer,
  myPhotos: myPhotosReducer,
  auth: authSliceReducer,
  family: familySliceReducer,
  stripe: stripeSliceReducer,
  data: fetchBackendDataReducer,
  myAchievements: myAchievementsReducer,
  myAdolescence: myAdolescenceReducer,
  myChildhood: myChildhoodReducer,
  myAdulthood: myAdulthoodReducer,
});

const rootReducer = (state, action) => {
  console.log("Action Type:", action.type);
  if (action.type === "user/clearAllData/fulfilled") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
