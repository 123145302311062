// src/store/cloudObjectSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../components/axiosConfig";
import { fetchBackendData } from "./fetchBackendData";

const initialState = {
  videoDescriptions: [],
  loading: false,
  error: null,
};

export const saveVideo = createAsyncThunk(
  "videos/saveVideo",
  async (file, { rejectWithValue, getState }) => {
    const { csrfToken } = getState().auth;
    console.log("Uploading video:", file);
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await axiosInstance.post(
        "https://memorydiaries.com/lifeData/videos/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "csrf-token": csrfToken,
          },
          withCredentials: true,
          timeout: 30000000,
        }
      );
      console.log("Upload response:", response.data);
      return response.data;
    } catch (error) {
      console.log("Upload error:", error);
      return rejectWithValue(error.response.data || error.message);
    }
  }
);

export const deleteVideo = createAsyncThunk(
  "videos/deleteVideo",
  async (fileKey, { rejectWithValue, getState }) => {
    const { csrfToken } = getState().auth;
    console.log("Deleting video:", fileKey);
    try {
      const response = await axiosInstance.delete(
        `https://memorydiaries.com/lifeData/videos/delete/${fileKey}`,
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );
      console.log("Deletion response:", response.data);
      return response.data;
    } catch (error) {
      console.log("Deletion error:", error);
      return rejectWithValue(error.response.data || error.message);
    }
  }
);

export const convertVideo = createAsyncThunk(
  "videos/convertVideo",
  async (formData, { rejectWithValue, getState }) => {
    const { csrfToken } = getState().auth;
    console.log("Converting video with FormData:", formData);
    try {
      // Directly use the passed formData
      const response = await axiosInstance.post(
        `https://memorydiaries.com/lifeData/videos/convert`, // Correct endpoint URL
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );

      console.log("Conversion response:", response.data);
      return response.data; // This should return metadata or a link, not the file itself
    } catch (error) {
      console.log("Conversion error:", error);
      return rejectWithValue(error.response.data || error.message);
    }
  }
);

const MyVideoSlice = createSlice({
  name: "videos",
  initialState,
  reducers: {
    editDescription: (state, action) => {
      console.log("editDescription action payload:", action.payload);
      const { key, text } = action.payload;
      const description = state.videoDescriptions.find(
        (desc) => desc.Key === key
      );
      if (description) {
        description.text = text;
      } else {
        state.videoDescriptions.push({ Key: key, text });
      }
      console.log("Updated redux videoDescriptions:", state.videoDescriptions);
    },
    deleteDescription: (state, action) => {
      state.videoDescriptions.splice(action.payload, 1);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveVideo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveVideo.fulfilled, (state, action) => {
        state.loading = false;
        const existingVideo = state.videos.find(
          (video) => video.Key === action.payload.Key
        );
        console.log("Video saved successfully");
        if (!existingVideo) {
          state.videos.push(action.payload);
        } else {
          alert("Video name already exists");
        }
      })
      .addCase(saveVideo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteVideo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteVideo.fulfilled, (state, action) => {
        state.loading = false;
        state.videos = state.videos.filter(
          (vid) => vid.Key !== action.payload.Key
        );
        console.log("Video deleted successfully");
      })
      .addCase(deleteVideo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(convertVideo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(convertVideo.fulfilled, (state, action) => {
        state.loading = false;
        state.videos = state.videos.map((vid) =>
          vid.Key === action.payload.Key ? action.payload : vid
        );
        console.log("Video converted successfully");
      })
      .addCase(convertVideo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchBackendData.fulfilled, (state, action) => {
        state.videoDescriptions =
          action.payload?.state?.videoDescriptions || [];
        console.log("Fetched videoDescriptions:", state.videoDescriptions);
      });
  },
});

export const { editDescription, deleteDescription } = MyVideoSlice.actions;
export default MyVideoSlice.reducer;
