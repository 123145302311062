import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  Button,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import DOMPurify from "dompurify";
import {
  createCustomerAndSubscription,
  selectStripeState,
} from "../store/stripeSlice";
import StripeIMG from "../Img/Stripe1.png";
import { generateNonce } from "../utils/generateNonce";

const Checkout = ({ open, onClose, onCheckoutSuccess, onCheckoutFailure }) => {
  const [email, setEmail] = useState("");
  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("US");
  const [plan, setPlan] = useState("free-plan");
  const [currency, setCurrency] = useState("usd"); // Add currency state
  const paymentElementRef = useRef(null);

  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const { clientSecret, loading, error } = useSelector(selectStripeState);

  useEffect(() => {
    if (elements && plan !== "free-plan") {
      const paymentElement = elements.create("payment");
      if (document.getElementById("payment-element")) {
        paymentElement.mount("#payment-element");
      } else {
        console.error("Payment element is not found in DOM");
      }
    }
  }, [elements, plan]);

  const handlePlanChange = (event) => {
    setPlan(event.target.value);
  };

  const handleSubscription = async () => {
    if (!stripe || !elements) {
      alert("Stripe has not loaded yet. Please try again.");
      return;
    }

    const sanitizedEmail = sanitiseInput(email);

    if (!validateEmail(sanitizedEmail)) {
      alert("Please enter a valid email.");
      return;
    }

    const address = {
      line1: sanitiseInput(line1),
      line2: sanitiseInput(line2),
      city: sanitiseInput(city),
      state: sanitiseInput(state),
      postal_code: sanitiseInput(postalCode),
    };

    if (!validateAddress(address)) {
      alert("Please enter a valid address.");
      return;
    }
    setCurrency(currency);
    const nonce = generateNonce();

    try {
      if (plan !== "free-plan") {
        const paymentElement = elements.getElement(PaymentElement);

        if (!paymentElement) {
          alert("Payment form is not initialized. Please try again.");
          return;
        }

        let result;
        if (clientSecret.includes("seti_")) {
          // Use confirmSetup() for SetupIntent
          result = await stripe.confirmSetup({
            elements,
            confirmParams: {
              payment_method_data: {
                billing_details: { email: sanitizedEmail, address },
              },
            },
            redirect: "if_required",
          });

          if (result.error) {
            console.error("Error confirming setup:", result.error.message);
            alert(
              "Failed to confirm setup. Please check your payment details."
            );
            return;
          }

          if (result.setupIntent.status !== "succeeded") {
            alert("Setup was not successful. Please try again.");
            return;
          }
        } else if (clientSecret.includes("pi_")) {
          // Use confirmPayment() for PaymentIntent
          result = await stripe.confirmPayment({
            elements,
            confirmParams: {
              payment_method_data: {
                billing_details: { email: sanitizedEmail, address },
                metadata: { nonce },
              },
              redirect: "if_required",
            },
          });

          if (result.error) {
            console.error("Error confirming payment:", result.error.message);
            alert(
              "Failed to confirm payment. Please check your payment details."
            );
            return;
          }

          if (result.paymentIntent.status !== "succeeded") {
            alert("Payment was not successful. Please try again.");
            return;
          }
        } else {
          alert("Unknown client secret type. Cannot proceed with payment.");
          return;
        }
      }

      const result = await dispatch(
        createCustomerAndSubscription({
          email: sanitizedEmail,
          address,
          plan_id: plan,
          currency,
          nonce,
        })
      ).unwrap();

      if (onCheckoutSuccess) onCheckoutSuccess(result);
      onClose();
    } catch (err) {
      if (onCheckoutFailure) onCheckoutFailure(err.message);
      alert("Failed to create customer and subscription. Please try again.");
    }
  };

  const sanitiseInput = (input) => {
    return input ? DOMPurify.sanitize(input) : "";
  };

  const validateAddress = (address) => {
    return (
      address.line1 &&
      address.city &&
      address.state &&
      address.postal_code &&
      address.line1.length > 0 &&
      address.city.length > 0 &&
      address.state.length > 0 &&
      address.postal_code.length > 0
    );
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return email && emailRegex.test(sanitiseInput(email));
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <div style={{ padding: 20 }}>
        <h1>Choose Your Subscription</h1>
        <h2>Free 7 day Trial</h2>
        <h3>
          Your card will not be charged until after the 7 day trial period. You
          can cancel a subscription at any time.
        </h3>
        <RadioGroup value={plan} onChange={handlePlanChange}>
          <FormControlLabel
            value="price_1PsGHWRwRACGWZcEPootbVTh"
            control={<Radio />}
            label="Free Plan"
          />
          <FormControlLabel
            value="price_1PsGGaRwRACGWZcEKcXrkvFk"
            control={<Radio />}
            label="Six-Monthly Subscription - $159.00"
          />
          <FormControlLabel
            value="price_1PsGIDRwRACGWZcEdPmVC9H9"
            control={<Radio />}
            label="Yearly Subscription - $300.00"
          />
        </RadioGroup>

        <TextField
          fullWidth
          label="Email"
          value={email}
          onChange={(e) => setEmail(sanitiseInput(e.target.value))}
          style={{ marginTop: 20 }}
          error={!validateEmail(email)}
          helperText={
            !validateEmail(email) ? "Please enter a valid email." : ""
          }
        />
        <TextField
          fullWidth
          label="Address Line 1"
          value={line1}
          onChange={(e) => setLine1(sanitiseInput(e.target.value))}
          style={{ marginTop: 20 }}
          error={!line1.length > 0}
          helperText={
            !line1.length > 0 ? "Please enter your street address." : ""
          }
        />

        <TextField
          fullWidth
          label="Address Line 2"
          value={line2}
          onChange={(e) => setLine2(sanitiseInput(e.target.value))}
          style={{ marginTop: 20 }}
          helperText="Optional"
        />

        <TextField
          fullWidth
          label="City"
          value={city}
          onChange={(e) => setCity(sanitiseInput(e.target.value))}
          style={{ marginTop: 20 }}
          error={!city.length > 0}
          helperText={!city.length > 0 ? "Please enter your city." : ""}
        />

        <TextField
          fullWidth
          label="State"
          value={state}
          onChange={(e) => setState(sanitiseInput(e.target.value))}
          style={{ marginTop: 20 }}
          error={!state.length > 0}
          helperText={!state.length > 0 ? "Please enter your state." : ""}
        />

        <TextField
          fullWidth
          label="Postal Code"
          value={postalCode}
          onChange={(e) => setPostalCode(sanitiseInput(e.target.value))}
          style={{ marginTop: 20 }}
          error={!postalCode.length > 0}
          helperText={
            !postalCode.length > 0 ? "Please enter your postal code." : ""
          }
        />

        {plan !== "free-plan" && (
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <div
              style={{
                marginTop: 20,
                marginBottom: 20,
                flexDirection: "row",
                display: "flex",
              }}
            >
              <h2>Payment details</h2>
              <img
                src={StripeIMG}
                alt="Stripe Logo"
                style={{
                  marginTop: 10,
                  width: "25%",
                  resizeMode: "contain",
                  marginLeft: "auto",
                }}
              />
            </div>
            <div
              id="payment-element"
              ref={paymentElementRef}
              style={{
                padding: 10,
                borderWidth: 1,
                borderColor: "gray",
                borderStyle: "solid",
                borderRadius: 10,
              }}
            >
              <PaymentElement />
            </div>
            <div style={{ marginTop: 20, textAlign: "center" }}>
              <Typography variant="body2" color="textSecondary">
                Your payment information is securely processed by
                <a
                  href="https://stripe.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    marginLeft: 4,
                    color: "#6772e5",
                    textDecoration: "none",
                  }}
                >
                  Stripe
                </a>
                . We never store your card details.
              </Typography>
            </div>
          </div>
        )}

        <Button
          variant="contained"
          sx={{
            backgroundColor: "#E29578",
            "&:hover": {
              backgroundColor: "#FFDDD2",
            },
          }}
          type="submit"
          disabled={!stripe || loading}
          onClick={handleSubscription}
          style={{ marginTop: 20 }}
        >
          {loading ? "Processing..." : "Confirm and Pay"}
        </Button>

        {error && (
          <Typography color="error" style={{ marginTop: 20 }}>
            {error}
          </Typography>
        )}
      </div>
    </Dialog>
  );
};

export default Checkout;
