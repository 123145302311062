import React from "react";
import { Typography, Container, Box } from "@mui/material";
import Contactus from "../Img/ContactUs.jpg";

const ContactUs = () => {
  return (
    <Container style={{ marginTop: 20 }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ flexDirection: "column", width: "100%" }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="body1">
            We welcome your feedback, so that we can continue to improve our
            products. Please feel free to reach out to us with any questions,
            comments, or suggestions. You can contact us via email at:
            dr.dbrown@icloud.com
          </Typography>
        </div>
        <div style={{ flexDirection: "column" }}>
          <img
            src={Contactus}
            alt="Contact Us"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
      </div>
    </Container>
  );
};

export default ContactUs;
