import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  Container,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  IconButton,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DownloadIcon from "@mui/icons-material/Download";
import imageCompression from "browser-image-compression";
import DOMPurify from "dompurify";
import { useSelector, useDispatch } from "react-redux";
import {
  savePhoto,
  deletePhoto,
  editDescription,
  deleteDescription,
} from "../store/MyPhotoSlice";
import axiosInstance from "./axiosConfig";

const supportedFormats = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/tiff",
  "image/bmp",
  "image/webp",
  "image/svg+xml",
  "image/jpg",
];

const MyPhotos = () => {
  const dispatch = useDispatch();
  const [photos, setPhotos] = useState([]);
  const loading = useSelector((state) => state.myPhotos.loading);
  const error = useSelector((state) => state.myPhotos.error);
  const photoDescriptions = useSelector(
    (state) => state.myPhotos.photoDescriptions
  );

  const [selectedFile, setSelectedFile] = useState(null);
  const [descriptions, setDescriptions] = useState(photoDescriptions);
  const [isEditing, setIsEditing] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [fetching, setFetching] = useState(true);
  const { csrfToken } = useSelector((state) => state.auth);

  useEffect(() => {
    if (photos.length > 0 && photoDescriptions.length > 0) {
      const updatedDescriptions = photos.map((photo) => {
        const matchingDescription = photoDescriptions.find(
          (desc) => String(desc.Key) === String(photo.Key)
        );
        return matchingDescription ? matchingDescription.text : "";
      });
      setDescriptions(updatedDescriptions);
    }
  }, [photos, photoDescriptions]);

  useEffect(() => {
    fetchPhotos();
    setRefresh(false);
  }, [refresh, csrfToken]);

  const fetchPhotos = async () => {
    setFetching(true);
    try {
      const response = await axiosInstance.get(
        "https://memorydiaries.com/lifeData/photos/download",
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );
      console.log("Response data:", response.data);
      if (Array.isArray(response.data)) {
        setPhotos(response.data);
        const initialDescriptions = response.data.map(() => "");
        setDescriptions(initialDescriptions);
      } else {
        console.error("Unexpected response format:", response.data);
      }
    } catch (error) {
      console.error("Error fetching photos:", error);
      setErrorMessage("Error fetching photos. Please try again later.");
    } finally {
      setFetching(false);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      setErrorMessage("No file selected");
      return;
    }
    if (!supportedFormats.includes(file.type)) {
      setErrorMessage(
        "Supported formats are: JPG, JPEG, PNG, GIF, TIFF, BMP, WebP, SVG"
      );
      return;
    }
    setErrorMessage("");
    const options = {
      maxWidthOrHeight: 1024,
      useWebWorker: true,
      fileType: "image/jpg",
    };
    try {
      const compressedFile = await imageCompression(file, options);
      const renamedFile = new File([compressedFile], file.name, {
        type: compressedFile.type,
        lastModified: Date.now(),
      });
      console.log("Compressed file:", renamedFile);
      setSelectedFile(renamedFile);
    } catch (error) {
      console.error("Error compressing the image:", error);
      setErrorMessage("Error processing the image");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (selectedFile) {
      setUploading(true);
      console.log("In handle submit selected file", selectedFile);
      const file = new FormData();
      file.append("file", selectedFile);
      await dispatch(savePhoto(file));
      setSelectedFile(null);
      setRefresh(true);
      setUploading(false);
    }
  };

  const handleDescriptionChange = (index, event) => {
    if (descriptions[index] !== undefined) {
      const sanitizedDescription = DOMPurify.sanitize(event.target.value);
      const newDescriptions = [...descriptions];
      newDescriptions[index] = sanitizedDescription;
      setDescriptions(newDescriptions);
    } else {
      console.error("Description index out of bounds");
    }
  };

  const handleSave = (index, photoKey) => {
    if (descriptions[index] !== undefined) {
      const sanitizedDescription = DOMPurify.sanitize(descriptions[index]);
      setDescriptions((prevDescriptions) => {
        const newDescriptions = [...prevDescriptions];
        newDescriptions[index] = sanitizedDescription;
        return newDescriptions;
      });
      dispatch(editDescription({ key: photoKey, text: sanitizedDescription })); // use photoKey instead of index
      setIsEditing(null);
      setRefresh(true);
    }
  };

  const handleEdit = (index) => {
    setIsEditing(index);
  };

  const handleDelete = async (photoKey) => {
    await dispatch(deletePhoto(photoKey));
    dispatch(deleteDescription(photoKey));
    setRefresh(true);
  };

  const handleDownload = (photoName) => {
    const photo = photos.find((pho) => pho.Key === photoName);
    if (photo && photo.base64) {
      const blob = new Blob([photo.file], { type: photo.contentType });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = photoName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      setErrorMessage("Photo not available for download");
    }
  };

  return (
    <Container style={{ marginTop: 20 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        My Photos
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        Upload Photos Related to Your Story
      </Typography>
      <p>Allowed formats: JPG, JPEG, PNG, GIF, TIFF, BMP, WebP, SVG</p>
      <p>If it is not one of these file types it will not upload</p>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          style={{ margin: "16px 0" }}
        />
        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
        <Button
          type="submit"
          variant="contained"
          sx={{
            backgroundColor: "#E29578",
            "&:hover": {
              backgroundColor: "#FFDDD2",
            },
          }}
          disabled={loading || !selectedFile || uploading}
        >
          {uploading ? "Uploading..." : "Upload"}
        </Button>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" component="h2" gutterBottom>
          Uploaded Photos
        </Typography>
        {fetching ? (
          <Typography variant="body1">Loading photos...</Typography>
        ) : photos.length > 0 ? (
          <List>
            {photos.map((photo, index) => (
              <ListItem
                key={index}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <ListItemAvatar>
                  <Avatar
                    src={`data:${photo.contentType};base64,${photo.base64}`}
                    alt={`Photo ${index + 1}`}
                    variant="square"
                    sx={{
                      width: "300px",
                      height: "300px",
                      objectFit: "contain",
                    }}
                  />
                </ListItemAvatar>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "16px",
                    flexGrow: 1,
                  }}
                >
                  <ListItemText
                    primary={photo.Key || `Photo ${index + 1}`}
                    secondary={descriptions[index]}
                    sx={{ marginLeft: "20px" }}
                  />
                  {isEditing === index ? (
                    <>
                      <TextField
                        value={descriptions[index]}
                        onChange={(event) =>
                          handleDescriptionChange(index, event)
                        }
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={6}
                        placeholder="Add a description"
                        margin="normal"
                      />
                      <IconButton
                        aria-label="save"
                        onClick={() => handleSave(index, photo.Key)}
                      >
                        <SaveIcon />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <Typography variant="body1">
                        {descriptions[index]}
                      </Typography>
                      <IconButton
                        aria-label="edit"
                        onClick={() => handleEdit(index)}
                      >
                        <EditIcon />
                      </IconButton>
                    </>
                  )}
                </Box>
                <IconButton
                  edge="end"
                  aria-label="download"
                  onClick={() => handleDownload(photo.Key)}
                  sx={{ marginLeft: "16px" }}
                >
                  <DownloadIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDelete(photo.Key)}
                  sx={{ marginLeft: "16px" }}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="body1">No photos uploaded yet.</Typography>
        )}
      </Box>
      {error && (
        <Typography variant="body2" color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
    </Container>
  );
};

export default MyPhotos;
