import React, { useEffect, useState } from "react";
import {
  Typography,
  Container,
  Box,
  List,
  ListItem,
  IconButton,
  ListItemText,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useSelector, useDispatch } from "react-redux";
import axiosInstance from "../axiosConfig";

const FamilyVideos = () => {
  const dispatch = useDispatch();
  const [videos, setVideos] = useState([]);
  const loading = useSelector((state) => state.myVideos.loading);
  const error = useSelector((state) => state.myVideos.error);
  const [fetching, setFetching] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [descriptions, setDescriptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [refresh, setRefresh] = useState(false);
  const { csrfToken } = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchVideos = async () => {
      setFetching(true);
      try {
        const response = await axiosInstance.get(
          "https://memorydiaries.com/lifeData/videos/download",
          {
            headers: {
              "Content-Type": "multipart/form-data", // Updated to application/json
              "csrf-token": csrfToken,
            },
            withCredentials: true,
          }
        );
        console.log("Response data:", response.data); // Log the response data
        setVideos(response.data);
        if (Array.isArray(response.data)) {
          setDescriptions(response.data.map(() => "")); // Initialize descriptions
        } else {
          console.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
      setFetching(false);
    };

    fetchVideos();
    setRefresh(false);
  }, [refresh, csrfToken]);

  const handleDownload = (base64, videoKey) => {
    const blob = base64ToBlob(base64, "video/webm");
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", `${videoKey}.webm`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const base64ToBlob = (base64, mimeType) => {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: mimeType });
  };

  return (
    <Container style={{ marginTop: 20 }}>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" component="h2" gutterBottom>
          Uploaded Videos:
        </Typography>
        {videos.length > 0 ? (
          <List>
            {videos.map((video, index) => {
              const blob = base64ToBlob(video.base64, "video/webm");
              const videoUrl = URL.createObjectURL(blob);

              return (
                <ListItem
                  key={index}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <video width="320" height="240" controls>
                    <source src={videoUrl} type="video/webm" />
                    Your browser does not support the video tag.
                  </video>
                  <ListItemText
                    primary={video.filename || `Video ${index + 1}`}
                    sx={{ marginLeft: "20px" }}
                  />
                  <IconButton
                    edge="end"
                    aria-label="download"
                    onClick={() => handleDownload(video.base64, video.Key)}
                    sx={{ marginLeft: "16px" }}
                  >
                    <DownloadIcon />
                  </IconButton>
                </ListItem>
              );
            })}
          </List>
        ) : (
          <Typography variant="body1">No videos uploaded yet.</Typography>
        )}
      </Box>
    </Container>
  );
};

export default FamilyVideos;
