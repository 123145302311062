// src/components/MyAchievements.js
import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, Container, Box } from '@mui/material';

const FamilyAchievements = () => {
  const achievements = useSelector((state) => state.myAchievements.achievements);

  return (
    <Container style={{ marginTop: 20 }}>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" component="h2" gutterBottom>
          Your Recorded Achievements:
        </Typography>
        {achievements.length > 0 ? (
          achievements.map((a, index) => (
            <Box
              key={index}
              sx={{ mb: 2, p: 2, border: '1px solid #ccc', borderRadius: '4px', display: 'flex', alignItems: 'center' }}
            >
              <Typography variant="body1" sx={{ flexGrow: 1 }}>{a.text}</Typography>
            </Box>
          ))
        ) : (
          <Typography variant="body1">No achievements recorded yet.</Typography>
        )}
      </Box>
    </Container>
  );
};

export default FamilyAchievements;
