import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../store/authSlice";
import { useNavigate } from "react-router-dom";
import safeRegex from "safe-regex";
import DOMPurify from "dompurify";
import axiosInstance from "./axiosConfig";

function Register({ open, onClose, onRegisterSuccess }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [codeGood, setCodeGood] = useState(false);
  const dispatch = useDispatch();
  const authStatus = useSelector((state) => state.auth.status);
  const authError = useSelector((state) => state.auth.error);
  const navigate = useNavigate();

  useEffect(() => {
    generateUniquePassword();
  }, []);

  const generateCode = () => {
    let genCode = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 6; i++) {
      genCode += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return genCode;
  };

  const isUniqueCode = async (code) => {
    try {
      const response = await axiosInstance.post(
        "https://memorydiaries.com/lifeauth/check-code-unique",
        { code },
        { withCredentials: true }
      );

      console.log("Response status:", response.status);
      console.log("Response data:", response.data);
      return response.data.isUnique;
    } catch (error) {
      console.error("Error checking code uniqueness:", error);
      return false;
    }
  };

  const generateUniquePassword = async () => {
    let unique = false;
    let newPassword;

    while (!unique) {
      newPassword = generateCode();
      unique = await isUniqueCode(newPassword);
    }

    setCode(newPassword);
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const nameRegex = /^[a-zA-Z0-9\s]{1,50}$/;
  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{4,}$/;
  //password regex set to 4 for testing pre-production

  const handleRegister = async () => {
    const sanitizedEmail = DOMPurify.sanitize(email);
    const sanitizedName = DOMPurify.sanitize(name);
    const sanitizedPassword = DOMPurify.sanitize(password);
    const sanitizedConfirmPassword = DOMPurify.sanitize(confirmPassword);

    if (!safeRegex(emailRegex) || !emailRegex.test(sanitizedEmail)) {
      alert("Invalid email format");
      return;
    }

    if (!safeRegex(nameRegex) || !nameRegex.test(sanitizedName)) {
      alert(
        "Invalid name format. Only alphanumeric characters and spaces are allowed."
      );
      return;
    }

    if (!safeRegex(passwordRegex) || !passwordRegex.test(sanitizedPassword)) {
      alert(
        "Password must be at least 8 characters long, contain at least one number, one lowercase and one uppercase letter."
      );
      return;
    }

    if (sanitizedPassword !== sanitizedConfirmPassword) {
      alert("Passwords do not match");
      return;
    }

    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    try {
      const result = await dispatch(
        registerUser({
          email: sanitizedEmail,
          password: sanitizedPassword,
          name: sanitizedName,
          code: code,
        })
      );

      if (
        registerUser.fulfilled.match(result) &&
        result.payload &&
        result.payload.name
      ) {
        onRegisterSuccess(result.payload.name);
        onClose(); // Close the registration dialog
      } else {
        console.log("Unable to access userName from payload");
      }
    } catch (error) {
      console.error("Failed to register:", error);
    }
  };

  const handleForgotPassword = () => {
    navigate("/reset-password");
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Register</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your registration details.
          </DialogContentText>
          {authError && (
            <DialogContentText style={{ color: "red" }}>
              {authError}
            </DialogContentText>
          )}
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            value={name}
            onChange={(e) => setName(DOMPurify.sanitize(e.target.value))}
          />
          <TextField
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(DOMPurify.sanitize(e.target.value))}
          />
          <TextField
            data-testid="pass1"
            margin="dense"
            label="Password"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(DOMPurify.sanitize(e.target.value))}
          />
          <TextField
            data-testid="pass2"
            margin="dense"
            label="Confirm Password"
            type="password"
            fullWidth
            value={confirmPassword}
            onChange={(e) =>
              setConfirmPassword(DOMPurify.sanitize(e.target.value))
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button
            data-testid="reg"
            onClick={handleRegister}
            color="primary"
            disabled={authStatus === "loading"}
          >
            Register
          </Button>
          <Button onClick={handleForgotPassword} color="secondary">
            Forgot Password?
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Register;
