import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { clearAllData, fetchBackendData } from "../store/fetchBackendData";
import { loginFamily } from "../store/authSlice";
import { fetchFamilyMembers } from "../store/FamilySlice";
import safeRegex from "safe-regex";
import DOMPurify from "dompurify";

const codeRegex = /^\d{16}$/; // Simple regex for a 16-digit code
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

function FamilyLogin({ open, onClose, onFamilySuccess }) {
  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [userName, setUserName] = useState("");
  const dispatch = useDispatch();
  const authStatus = useSelector((state) => state.auth.status);
  const authError = useSelector((state) => state.auth.error);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    const handlePostLogin = async () => {
      try {
        // eslint-disable-next-line
        const clearDataResult = await dispatch(clearAllData());
        if (clearAllData.fulfilled.match(clearDataResult)) {
        } else {
          alert("Failed to clear data:", clearDataResult.payload);
        }

        if (token) {
          try {
            // eslint-disable-next-line
            const resultAction = await dispatch(fetchBackendData(token));
            const familyMembers = await dispatch(fetchFamilyMembers(token));
            console.log("Backend data fetched:", resultAction, familyMembers);
            alert("login successful");
          } catch (error) {
            console.log("No backend data found or fetch failed");
            console.error("Fetch backend data error:", error);
          }
        } else {
          console.log("Login failed, token doesn't exist");
        }

        if (userName) {
          onFamilySuccess();
          onClose();
        } else {
          throw new Error("User name is missing in the login response");
        }
      } catch (error) {
        console.error("Failed to clear data or fetch backend data:", error);
      }
    };

    if (authStatus === "succeeded" && token && userName) {
      handlePostLogin();
    }
    // eslint-disable-next-line
  }, [userName]);

  const handleLogin = async () => {
    try {
      const sanitizedCode = DOMPurify.sanitize(code);
      if (!safeRegex(codeRegex) || !codeRegex.test(sanitizedCode)) {
        setError("Invalid 16-digit code.");
        return;
      }

      const sanitizedEmail = DOMPurify.sanitize(email);
      if (!safeRegex(emailRegex) || !emailRegex.test(sanitizedEmail)) {
        setError("Invalid email format.");
        return;
      }

      console.log(`Dispatching loginUser with code: ${code}, email: ${email}`);
      // eslint-disable-next-line
      const result = await dispatch(
        loginFamily({ code: sanitizedCode, email: sanitizedEmail })
      );
      alert("Login Result:" + JSON.stringify(result));
      // eslint-disable-next-line
      if (result.payload && result.payload.name) {
        setUserName(result.payload.name);
      } else {
        console.log("unable to access userName from payload");
        setError("Invalid code or email address.");
      }
    } catch (error) {
      console.error("Failed to log in:", error);
      setError("Failed to log in");
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Family Login</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter your unique 16-digit code and email address to log in.
        </DialogContentText>
        {authError && (
          <DialogContentText style={{ color: "red" }}>
            {authError}
          </DialogContentText>
        )}
        <TextField
          autoFocus
          margin="dense"
          label="16 Digit Code"
          type="text"
          fullWidth
          value={code}
          onChange={(e) => setCode(DOMPurify.sanitize(e.target.value))}
        />
        <TextField
          margin="dense"
          label="Email Address"
          type="email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(DOMPurify.sanitize(e.target.value))}
        />
        {error && <Typography style={{ color: "red" }}>{error}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleLogin}
          color="primary"
          disabled={authStatus === "loading"}
        >
          Login
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FamilyLogin;
