import { createSlice } from "@reduxjs/toolkit";
import { fetchBackendData } from "./fetchBackendData";

const initialState = {
  adolescentStories: [],
};

const myAdolescenceSlice = createSlice({
  name: "myAdolescence",
  initialState,
  reducers: {
    addStory: (state, action) => {
      state.adolescentStories.push({ text: action.payload });
    },
    editStory: (state, action) => {
      const { index, text } = action.payload;
      state.adolescentStories[index].text = text;
    },
    deleteStory: (state, action) => {
      state.adolescentStories.splice(action.payload, 1);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBackendData.fulfilled, (state, action) => {
      state.adolescentStories = action.payload?.state?.adolescentStories || [];
    });
  },
});

export const { addStory, editStory, deleteStory } = myAdolescenceSlice.actions;

export default myAdolescenceSlice.reducer;
