import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { Provider, useSelector, useDispatch } from "react-redux";
import store from "./store";
import NavigationDrawer from "./NavigationDrawer";
import FamilyNavigationDrawer from "./FamilyNavigationDrawer";
import Welcome from "./components/Welcome";
import Profile from "./components/Profile";
import EditProfile from "./components/EditProfile";
import ContactUs from "./components/ContactUs";
import MyChildhood from "./components/MyChildhood";
import MyAdolescence from "./components/MyAdolescence";
import MyAdulthood from "./components/MyAdulthood";
import MyAchievements from "./components/MyAchievements";
import MyDocuments from "./components/MyDocuments";
import MyPhotos from "./components/MyPhotos";
import MyVideos from "./components/MyVideos";
import FamilyAchievements from "./components/Family/FamilyAchievement";
import FamilyDocuments from "./components/Family/FamilyDocuments";
import FamilyPhotos from "./components/Family/FamilyPhotos";
import FamilyVideos from "./components/Family/FamilyVideos";
import FamilyAdolecence from "./components/Family/FamilyAdolescent";
import FamilyAdulthood from "./components/Family/FamilyAdulthood";
import FamilyChildhood from "./components/Family/FamilyChildhood";
import Checkout from "./components/Checkout";
import StateDataDisplay from "./components/StateDataDisplay";
import Login from "./components/Login";
import Register from "./components/Register";
import FamilyLogin from "./components/FamilyLogin";
import AddFamily from "./components/AddFamily";
import EditFamily from "./components/EditFamily";
import UpdatePayment from "./components/UpdatePayment";
import ResetPass from "./components/ResetPass";
import { logout } from "./store/authSlice";
import { checkPaymentStatus, fetchClientSecret } from "./store/stripeSlice"; // Import fetchClientSecret
import ProfilePlaceholder from "./Img/ProfilePlaceholder.jpg";
import { Elements } from "@stripe/react-stripe-js"; // Import Elements provider
import { loadStripe } from "@stripe/stripe-js"; // Import loadStripe
import { clearAllData } from "./store/fetchBackendData";
import { generateNonce } from "./utils/generateNonce";
import { initializeStripe } from "./stripe-init";

const stripePromise = loadStripe(
  "pk_test_51PfEUYRwRACGWZcExn42V3j72m5xHU8KU98oz3AtxzOmtW3BJiK3qzyCdceSHXPdoHZtHYqNdOYmDoeNMO1uG7Ns00oRvUhffc"
);

function App() {
  return (
    <Provider store={store}>
      <Router>
        <AppContent />
      </Router>
    </Provider>
  );
}

function AppContent() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const isPaid = useSelector((state) => state.stripe.isPaid);
  const clientSecret = useSelector((state) => state.stripe.clientSecret);
  const [loggedIn, setLoggedIn] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loginOpen, setLoginOpen] = useState(false);
  const [registerOpen, setRegisterOpen] = useState(false);
  const [resetPassOpen, setResetPassOpen] = useState(false);
  const [familyOpen, setFamilyOpen] = useState(false);
  const [familyLogedIn, setFamilyLogedIn] = useState(false);
  const [checkoutOpen, setCheckoutOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkPayment = async () => {
      console.log("inside App.js useEffect");
      if (user && user.id) {
        try {
          await dispatch(checkPaymentStatus());
          await dispatch(fetchClientSecret(user.email));
          console.log("Client secret:", clientSecret);
          console.log("after dispatch checkPaymentStatus", isPaid);
          if (!isPaid) {
            setCheckoutOpen(true);
            console.log("User is not paid.");
          } else {
            console.log("User has Already Paid.");
          }
        } catch (error) {
          console.error("Error during payment check:", error);
        }
      }
    };
    checkPayment();
  }, [user, isPaid, dispatch, clientSecret]);

  useEffect(() => {
    const nonce = generateNonce();
    const cspMetaTag = document.createElement("meta");
    cspMetaTag.setAttribute("http-equiv", "Content-Security-Policy");
    cspMetaTag.setAttribute(
      "content",
      `
      default-src 'self'; 
      script-src 'self' https://r.stripe.com https://m.stripe.com https://js.stripe.com https://api.stripe.com https://cdn.jsdelivr.net https://pay.google.com 'nonce-${nonce}';
      style-src 'self' 'unsafe-inline' https://fonts.googleapis.com; 
      media-src 'self' data: blob:;
      img-src 'self' data: blob: https://*.s3.private.au-syd.cloud-object-storage.appdomain.cloud;
      frame-src https://js.stripe.com https://pay.google.com;
      connect-src 'self' https://r.stripe.com https://js.stripe.com https://api.stripe.com https://cdn.jsdelivr.net https://pay.google.com https://fonts.googleapis.com;
      worker-src 'self' blob:;
      object-src 'none';
      base-uri 'self';
      form-action 'self';
    `
    );
    document.head.appendChild(cspMetaTag);
    const stripeScriptTag = document.createElement("script");
    stripeScriptTag.setAttribute("nonce", nonce);
    stripeScriptTag.src = "https://js.stripe.com/v3/";
    document.body.appendChild(stripeScriptTag);
    stripeScriptTag.onload = () => {
      console.log("Stripe.js loaded");
    };
  }, []);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFamilyMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFamilyMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setLoggedIn(false);
    dispatch(logout());
    dispatch(clearAllData());
    handleMenuClose();
    navigate("/");
  };

  const handleLoginClick = () => {
    setLoginOpen(true);
  };

  const handleRegisterClick = () => {
    setRegisterOpen(true);
  };

  const handleFamilyClick = () => {
    setFamilyOpen(true);
  };

  const handleResetPassClose = () => {
    setResetPassOpen(false);
  };

  const handleLoginSuccess = () => {
    console.log("Inside handleLoginSuccess function");
    setLoggedIn(true);
    setLoginOpen(false);
  };

  const handleRegisterSuccess = () => {
    console.log("Inside handleRegisterSuccess function");
    setRegisterOpen(false);
    setLoggedIn(true);
  };

  const handleCheckoutSuccess = async () => {
    console.log("Inside handleCheckoutSuccess function");
    try {
      if (isPaid) {
        setCheckoutOpen(false);
        navigate("/");
        alert("Payment plan successful!");
      } else {
        navigate("/");
      }
    } catch (error) {
      console.error("Error during payment:", error);
      alert("Error during payment. Please try again.");
    }
  };

  const handleCheckoutFailure = (errorMessage) => {
    setCheckoutOpen(false);
    alert(`Checkout failed: ${errorMessage}`);
    navigate("/");
  };

  const handleFamilySuccess = () => {
    setLoggedIn(true);
    setFamilyLogedIn(true);
    setFamilyOpen(false);
  };
  // style={{ backgroundColor: "#006D77" }}

  return (
    <div className="App">
      <AppBar
        style={{ background: "linear-gradient(to right, #3F704D, #1F3B24)" }}
        position="static"
      >
        <Toolbar>
          {loggedIn ? (
            familyLogedIn ? (
              <>
                <FamilyNavigationDrawer />
                <Typography
                  variant="h5"
                  style={{
                    marginLeft: 20,
                    fontFamily: "Verdana",
                    fontWeight: "bold",
                  }}
                >
                  Memory Diaries
                </Typography>
                <Button
                  color="inherit"
                  onClick={handleFamilyMenuClick}
                  style={{ marginLeft: "auto" }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <img
                      src={user?.profileImage || ProfilePlaceholder}
                      alt={user ? user.name : "User"}
                      style={{
                        width: 30,
                        height: 30,
                        borderRadius: "50%",
                        marginRight: 10,
                      }}
                    />
                    {user ? user.name : "User"}
                  </div>
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleFamilyMenuClose}
                >
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </>
            ) : (
              <>
                <NavigationDrawer />
                <Typography
                  variant="h5"
                  style={{
                    marginLeft: 20,
                    fontFamily: "Verdana",
                    fontWeight: "bold",
                  }}
                >
                  Memory Diaries
                </Typography>
                <Button
                  color="inherit"
                  onClick={handleMenuClick}
                  style={{ marginLeft: "auto" }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <img
                      src={user?.profileImage || ProfilePlaceholder}
                      alt={user ? user.name : "User"}
                      style={{
                        width: 30,
                        height: 30,
                        borderRadius: "50%",
                        marginRight: 10,
                      }}
                    />
                    {user ? user.name : "User"}
                  </div>
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </>
            )
          ) : (
            <>
              <MenuIcon />
              <Typography
                variant="h5"
                style={{
                  marginLeft: 20,
                  fontFamily: "Verdana",
                  fontWeight: "bold",
                }}
              >
                Memory Diaries
              </Typography>
              <Button
                color="inherit"
                style={{ marginLeft: "auto" }}
                onClick={handleLoginClick}
              >
                Your Login
              </Button>
              <Button color="inherit" onClick={handleRegisterClick}>
                Register
              </Button>
              <Button color="inherit" onClick={handleFamilyClick}>
                Family Login
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Container>
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/home" element={<Welcome />} />
          {loggedIn && (
            <>
              <Route path="/profile" element={<Profile />} />
              <Route path="/mychildhood" element={<MyChildhood />} />
              <Route path="/myadolescence" element={<MyAdolescence />} />
              <Route path="/myadulthood" element={<MyAdulthood />} />
              <Route path="/myachievements" element={<MyAchievements />} />
              <Route path="/mydocuments" element={<MyDocuments />} />
              <Route path="/myphotos" element={<MyPhotos />} />
              <Route path="/myvideos" element={<MyVideos />} />
              <Route path="/contactus" element={<ContactUs />} />
              <Route path="/statedata" element={<StateDataDisplay />} />
              <Route path="/edit-profile" element={<EditProfile />} />
              <Route
                path="/update-payment"
                element={
                  <Elements stripe={stripePromise} options={{ clientSecret }}>
                    {<UpdatePayment />}
                  </Elements>
                }
              />
              <Route path="/add-family" element={<AddFamily />} />
              <Route path="/edit-family" element={<EditFamily />} />
              <Route
                path="/familyachievements"
                element={<FamilyAchievements />}
              />
              <Route path="/familydocuments" element={<FamilyDocuments />} />
              <Route path="/familyphotos" element={<FamilyPhotos />} />
              <Route path="/familyvideos" element={<FamilyVideos />} />
              <Route path="/familyadolescence" element={<FamilyAdolecence />} />
              <Route path="/familyadulthood" element={<FamilyAdulthood />} />
              <Route path="/familychildhood" element={<FamilyChildhood />} />
              {!isPaid && (
                <Route
                  path="/checkout-form"
                  element={
                    <Elements stripe={stripePromise} options={{ clientSecret }}>
                      {" "}
                      {/* Wrap Checkout in Elements */}
                      <Checkout
                        open={checkoutOpen}
                        onClose={() => setCheckoutOpen(false)}
                        onCheckoutSuccess={handleCheckoutSuccess}
                        onCheckoutFailure={handleCheckoutFailure}
                      />
                    </Elements>
                  }
                />
              )}
            </>
          )}
        </Routes>
        {loginOpen && (
          <Login
            open={loginOpen}
            onClose={() => setLoginOpen(false)}
            onLoginSuccess={handleLoginSuccess} // Pass function reference correctly
          />
        )}
        {registerOpen && (
          <Register
            open={registerOpen}
            onClose={() => setRegisterOpen(false)}
            onRegisterSuccess={handleRegisterSuccess}
          />
        )}
        {resetPassOpen && (
          <ResetPass open={resetPassOpen} onClose={handleResetPassClose} />
        )}
        {familyOpen && (
          <FamilyLogin
            open={familyOpen}
            onClose={() => setFamilyOpen(false)}
            onFamilySuccess={handleFamilySuccess}
          />
        )}
        {loggedIn && !isPaid && clientSecret && (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            {/* Wrap Checkout in Elements here as well */}
            <Checkout
              open={checkoutOpen}
              isPaid={isPaid}
              onClose={() => setCheckoutOpen(false)}
              onCheckoutSuccess={handleCheckoutSuccess}
              onCheckoutFailure={handleCheckoutFailure}
            />
          </Elements>
        )}
      </Container>
    </div>
  );
}

export default App;
