import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addFamilyMember, clearError } from "../store/FamilySlice";
import DOMPurify from "dompurify";
import safeRegex from "safe-regex";

function AddFamily() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.family);

  const [name, setName] = useState("");
  const [relation, setRelation] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneNumberRegex = /^\d+$/;

  const handleSubmit = (e) => {
    e.preventDefault();

    const sanitizedInputs = {
      name: DOMPurify.sanitize(name),
      relation: DOMPurify.sanitize(relation),
      email: DOMPurify.sanitize(email),
      phoneNumber: DOMPurify.sanitize(phoneNumber),
    };

    if (
      !sanitizedInputs.name ||
      !sanitizedInputs.relation ||
      !sanitizedInputs.email ||
      !sanitizedInputs.phoneNumber
    ) {
      alert("All fields are required.");
      return;
    }

    if (!safeRegex(emailRegex) || !emailRegex.test(sanitizedInputs.email)) {
      alert("Please enter a valid email.");
      return;
    }

    if (
      !safeRegex(phoneNumberRegex) ||
      !phoneNumberRegex.test(sanitizedInputs.phoneNumber)
    ) {
      alert("Please enter a valid phone number with only numerical values.");
      return;
    }

    const familyMemberData = {
      ...sanitizedInputs,
    };

    dispatch(addFamilyMember(familyMemberData)).then((result) => {
      if (result.meta.requestStatus === "fulfilled") {
        navigate("/profile");
      }
    });
  };

  const handleCancel = () => {
    navigate("/profile"); // or navigate to the profile page or the previous page
  };

  const buttonStyle = {
    fontSize: "18px",
    padding: "10px 20px",
    color: "white",
    backgroundColor: "#E29578",
    border: "none",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  };

  const buttonHoverStyle = {
    backgroundColor: "#FFDDD2",
  };

  return (
    <div
      style={{
        padding: "20px",
        maxWidth: "600px",
        margin: "auto",
        fontSize: "18px",
      }}
    >
      <h2 style={{ textAlign: "center" }}>Add Family Member</h2>
      <form
        onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column", gap: "15px" }}
      >
        <div>
          <label>Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(DOMPurify.sanitize(e.target.value))}
            style={{ fontSize: "16px", padding: "8px", width: "100%" }}
            required
          />
        </div>
        <div>
          <label>Relation:</label>
          <input
            type="text"
            value={relation}
            onChange={(e) => setRelation(DOMPurify.sanitize(e.target.value))}
            style={{ fontSize: "16px", padding: "8px", width: "100%" }}
            required
          />
        </div>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(DOMPurify.sanitize(e.target.value))}
            style={{ fontSize: "16px", padding: "8px", width: "100%" }}
            required
          />
        </div>
        <div>
          <label>Phone Number:</label>
          <input
            type="tel"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(DOMPurify.sanitize(e.target.value))}
            style={{ fontSize: "16px", padding: "8px", width: "100%" }}
          />
        </div>
        {error && (
          <div>
            <p>{error}</p>
            <button type="button" onClick={() => dispatch(clearError())}>
              Clear Error
            </button>
          </div>
        )}
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <button
            type="submit"
            style={buttonStyle}
            onMouseOver={(e) =>
              (e.currentTarget.style.backgroundColor =
                buttonHoverStyle.backgroundColor)
            }
            onMouseOut={(e) =>
              (e.currentTarget.style.backgroundColor =
                buttonStyle.backgroundColor)
            }
            onFocus={(e) =>
              (e.currentTarget.style.backgroundColor =
                buttonHoverStyle.backgroundColor)
            }
            onBlur={(e) =>
              (e.currentTarget.style.backgroundColor =
                buttonStyle.backgroundColor)
            }
            disabled={loading}
          >
            {loading ? "Adding..." : "Add"}
          </button>
          <button
            type="button"
            onClick={handleCancel}
            style={buttonStyle}
            onMouseOver={(e) =>
              (e.currentTarget.style.backgroundColor =
                buttonHoverStyle.backgroundColor)
            }
            onMouseOut={(e) =>
              (e.currentTarget.style.backgroundColor =
                buttonStyle.backgroundColor)
            }
            onFocus={(e) =>
              (e.currentTarget.style.backgroundColor =
                buttonHoverStyle.backgroundColor)
            }
            onBlur={(e) =>
              (e.currentTarget.style.backgroundColor =
                buttonStyle.backgroundColor)
            }
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddFamily;
