import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFamilyMembers,
  updateFamilyMember,
  deleteFamilyMember,
} from "../store/FamilySlice";
import DOMPurify from "dompurify";
import { useNavigate } from "react-router-dom";
import safeRegex from "safe-regex";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const phoneRegex = /^[0-9]{10,15}$/;

const EditFamily = () => {
  const dispatch = useDispatch();
  const { familyMembers, loading, error } = useSelector(
    (state) => state.family
  );
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [editForm, setEditForm] = useState({
    name: "",
    relation: "",
    email: "",
    phoneNumber: "",
  });
  const [refresh, setRefresh] = useState(false);
  const navigation = useNavigate();

  useEffect(() => {
    dispatch(fetchFamilyMembers());
    setRefresh(false);
  }, [dispatch, refresh]);

  const handleSelect = (index) => {
    setSelectedIndex(index);
    setEditForm(familyMembers[index]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = DOMPurify.sanitize(value);
    setEditForm({ ...editForm, [name]: sanitizedValue });
  };

  const handleSave = () => {
    if (!editForm.name.trim() || !editForm.relation.trim()) {
      alert("Name and Relation are required.");
      return;
    }

    if (!safeRegex(emailRegex) || !emailRegex.test(editForm.email)) {
      alert("Please enter a valid email.");
      return;
    }

    if (
      editForm.phoneNumber &&
      (!safeRegex(phoneRegex) || !phoneRegex.test(editForm.phoneNumber))
    ) {
      alert("Please enter a valid phone number with only numerical values.");
      return;
    }

    dispatch(updateFamilyMember(editForm, familyMembers[selectedIndex].id));
    setSelectedIndex(null);
    setRefresh(true);
  };

  const handleCancel = () => {
    navigation("/profile");
  };

  const handleDelete = () => {
    if (
      window.confirm(
        "Are you sure you want to delete this family member? This action cannot be undone."
      )
    ) {
      dispatch(deleteFamilyMember(familyMembers[selectedIndex].id));
    }
    navigation("/profile");
  };

  const buttonStyle = {
    fontSize: "18px",
    padding: "10px 20px",
    color: "white",
    backgroundColor: "#E29578",
    border: "none",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  };

  const buttonHoverStyle = {
    backgroundColor: "#FFDDD2",
  };

  return (
    <div>
      <h2>Family Members:</h2>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      <div style={{ display: "flex", maxHeight: "400px", overflowY: "scroll" }}>
        <div style={{ flex: 1, marginRight: "20px" }}>
          {familyMembers.map((member, index) => (
            <div
              key={index}
              onClick={() => handleSelect(index)}
              style={{
                cursor: "pointer",
                fontSize: 20,
                padding: 5,
                color: selectedIndex === index ? "blue" : "black",
              }}
            >
              {member.name}
            </div>
          ))}
        </div>
        {selectedIndex !== null && (
          <div
            style={{
              display: "flex",
              flex: 2,
              flexDirection: "column",
              fontSize: 18,
            }}
          >
            <label style={{ marginBottom: 10, fontWeight: "bold" }}>
              Name:
            </label>
            <input
              type="text"
              name="name"
              value={editForm.name}
              onChange={handleChange}
              placeholder="Name"
              style={{ marginBottom: 20, fontSize: 18 }}
            />
            <label style={{ marginBottom: 10, fontWeight: "bold" }}>
              Relation:
            </label>
            <input
              type="text"
              name="relation"
              value={editForm.relation}
              onChange={handleChange}
              placeholder="Relation"
              style={{ marginBottom: 20, fontSize: 18 }}
            />
            <label style={{ marginBottom: 10, fontWeight: "bold" }}>
              Email:
            </label>
            <input
              type="email"
              name="email"
              value={editForm.email}
              onChange={handleChange}
              placeholder="Email"
              style={{ marginBottom: 20, fontSize: 18 }}
            />
            <label style={{ marginBottom: 10, fontWeight: "bold" }}>
              Phone Number:
            </label>
            <input
              type="text"
              name="phoneNumber"
              value={editForm.phoneNumber}
              onChange={handleChange}
              placeholder="Phone Number"
              style={{ marginBottom: 20, fontSize: 18 }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <button
                onClick={handleSave}
                style={buttonStyle}
                onMouseOver={(e) =>
                  (e.currentTarget.style.backgroundColor =
                    buttonHoverStyle.backgroundColor)
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.backgroundColor =
                    buttonStyle.backgroundColor)
                }
                onFocus={(e) =>
                  (e.currentTarget.style.backgroundColor =
                    buttonHoverStyle.backgroundColor)
                } // Added onFocus
                onBlur={(e) =>
                  (e.currentTarget.style.backgroundColor =
                    buttonStyle.backgroundColor)
                } // Added onBlur
              >
                Save
              </button>
              <button
                onClick={handleCancel}
                style={buttonStyle}
                onMouseOver={(e) =>
                  (e.currentTarget.style.backgroundColor =
                    buttonHoverStyle.backgroundColor)
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.backgroundColor =
                    buttonStyle.backgroundColor)
                }
                onFocus={(e) =>
                  (e.currentTarget.style.backgroundColor =
                    buttonHoverStyle.backgroundColor)
                } // Added onFocus
                onBlur={(e) =>
                  (e.currentTarget.style.backgroundColor =
                    buttonStyle.backgroundColor)
                } // Added onBlur
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                style={buttonStyle}
                onMouseOver={(e) =>
                  (e.currentTarget.style.backgroundColor =
                    buttonHoverStyle.backgroundColor)
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.backgroundColor =
                    buttonStyle.backgroundColor)
                }
                onFocus={(e) =>
                  (e.currentTarget.style.backgroundColor =
                    buttonHoverStyle.backgroundColor)
                } // Added onFocus
                onBlur={(e) =>
                  (e.currentTarget.style.backgroundColor =
                    buttonStyle.backgroundColor)
                } // Added onBlur
              >
                Delete
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditFamily;
