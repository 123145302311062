export const initializeStripe = (clientSecret) => {
  if (!window.Stripe) {
    throw new Error("Stripe.js not loaded");
  }

  try {
    const stripe = window.Stripe(
      "pk_test_51PfEUYRwRACGWZcExn42V3j72m5xHU8KU98oz3AtxzOmtW3BJiK3qzyCdceSHXPdoHZtHYqNdOYmDoeNMO1uG7Ns00oRvUhffc"
    );

    const elements = stripe.elements({
      clientSecret: clientSecret, // should be the valid clientSecret
      fonts: [
        {
          cssSrc: "https://fonts.googleapis.com/css?family=Roboto:300,400,600",
        },
      ],
    });

    const paymentElement = elements.create("payment");
    paymentElement.mount("#payment-element");
    return { stripe, elements };
  } catch (error) {
    console.error("Error initializing Stripe:", error);
    throw new Error("Stripe initialization failed");
  }
};
