import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, Container, Box } from '@mui/material';

const FamilyAdulthood = () => {
  const stories = useSelector((state) => state.myAdulthood.adultStories);

  return (
    <Container style={{marginTop: 20}}>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" component="h2" gutterBottom>
          Your Recorded Stories:
        </Typography>
        {stories.length > 0 ? (
          stories.map((s, index) => (
            <Box
              key={index}
              sx={{ mb: 2, p: 2, border: '1px solid #ccc', borderRadius: '4px', display: 'flex', alignItems: 'center' }}
            >
              <Typography variant="body1" sx={{ flexGrow: 1 }}>{s.text}</Typography>
            </Box>
          ))
        ) : (
          <Typography variant="body1">No stories recorded yet.</Typography>
        )}
      </Box>
    </Container>
  );
};

export default FamilyAdulthood;
