import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  Container,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import { useSelector, useDispatch } from "react-redux";
import { saveDocument, deleteDocument } from "../store/MyDocumentSlice";
import axiosInstance from "./axiosConfig";

const MyDocuments = () => {
  const dispatch = useDispatch();
  const [documents, setDocuments] = useState([]);
  const loading = useSelector((state) => state.myDocuments.loading);
  const error = useSelector((state) => state.myDocuments.error);

  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [descriptions, setDescriptions] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [fetching, setFetching] = useState(true);
  const { csrfToken } = useSelector((state) => state.auth);

  useEffect(() => {
    // Fetch photos from the backend
    const fetchDocuments = async () => {
      setFetching(true);
      try {
        const response = await axiosInstance.get(
          "https://memorydiaries.com/lifeData/documents/download",
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "csrf-token": csrfToken,
            },
            withCredentials: true,
          }
        );
        console.log("Response data:", response.data); // Log the response data
        setDocuments(response.data);
        if (Array.isArray(response.data)) {
          setDescriptions(response.data.map(() => "")); // Initialize descriptions
        } else {
          console.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching photos:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchDocuments();
    setRefresh(false);
  }, [refresh]);

  const allowedFileTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.apple.pages",
    "text/plain",
  ];
  const maxFileSize = 10 * 1024 * 1024; // 10 MB

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (!allowedFileTypes.includes(file.type)) {
        setErrorMessage("File type not allowed.");
        setSelectedFile(null);
      } else if (file.size > maxFileSize) {
        setErrorMessage("File size exceeds 10 MB.");
        setSelectedFile(null);
      } else {
        setErrorMessage("");
        setSelectedFile(file);
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (selectedFile) {
      setUploading(true);
      console.log("In handle submit selected file", selectedFile);
      const file = new FormData();
      file.append("file", selectedFile);
      await dispatch(saveDocument(file));
      setSelectedFile(null);
      setRefresh(true);
      setUploading(false);
    }
  };

  const handleDelete = async (docKey) => {
    if (window.confirm("Are you sure you want to delete this document?")) {
      await dispatch(deleteDocument(docKey));
      setRefresh(true); // Refresh document list after delete
    }
  };

  const handleDownload = (docKey) => {
    const document = documents.find((doc) => doc.Key === docKey);
    if (document) {
      const fileUrl = document.fileUrl; // Assuming you have a URL from the backend
      if (fileUrl) {
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", document.name || document.Key);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        console.error("No valid file URL found for download.");
      }
    }
  };

  return (
    <Container style={{ marginTop: 20 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Record Documents
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        Upload Documents Related to Your Story
      </Typography>
      <p>Allowed File Types: PDF, DOC, DOCX, PAGES, TXT</p>
      <p>If it is not one of these file types it will not upload</p>
      <p>Maximum file size: 10MB</p>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <input
          type="file"
          onChange={handleFileChange}
          style={{ margin: "16px 0" }}
        />
        {errorMessage && (
          <Typography variant="body2" color="error">
            {errorMessage}
          </Typography>
        )}
        <Button
          type="submit"
          variant="contained"
          sx={{
            backgroundColor: "#E29578",
            "&:hover": {
              backgroundColor: "#FFDDD2",
            },
          }}
          disabled={loading || !selectedFile}
        >
          {uploading ? "Uploading..." : "Upload"}
        </Button>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" component="h2" gutterBottom>
          Uploaded Documents
        </Typography>
        {fetching ? (
          <Typography variant="body1">Loading documents...</Typography>
        ) : documents.length > 0 ? (
          <List>
            {documents.map((doc, index) => (
              <ListItem
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <ListItemText primary={doc.name || doc.Key} />
                <div>
                  <IconButton
                    aria-label="download"
                    onClick={() => handleDownload(doc.Key)}
                  >
                    <DownloadIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleDelete(doc.Key)}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="body1">No documents uploaded yet.</Typography>
        )}
      </Box>
      {error && (
        <Typography variant="body2" color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
    </Container>
  );
};

export default MyDocuments;
