// src/middleware/saveFormDataMiddleware.js
import axiosInstance from "../components/axiosConfig";

const saveFormDataMiddleware = (store) => (next) => async (action) => {
  console.log("SaveFormDataMiddleware triggered for:", action.type);
  const result = next(action);

  const apiUrl = `https://memorydiaries.com/lifeData/saveState`;

  if (
    action.type === "myAchievements/addAchievement" ||
    action.type === "myAchievements/editAchievement" ||
    action.type === "myAchievements/deleteAchievement" ||
    action.type === "myAdolescence/addStory" ||
    action.type === "myAdolescence/editStory" ||
    action.type === "myAdolescence/deleteStory" ||
    action.type === "myAdulthood/addStory" ||
    action.type === "myAdulthood/editStory" ||
    action.type === "myAdulthood/deleteStory" ||
    action.type === "myChildhood/addStory" ||
    action.type === "myChildhood/editStory" ||
    action.type === "myChildhood/deleteStory" ||
    action.type === "photos/editDescription" ||
    action.type === "photos/deleteDescription" ||
    action.type === "videos/editDescription" ||
    action.type === "videos/deleteDescription"
  ) {
    console.log("Dispatching action type:", action.type);
    const currentState = store.getState();
    console.log("Current state before saving:", currentState);
    const selectedState = {
      childhoodStories: currentState.myChildhood?.childhoodStories || [],
      adolescentStories: currentState.myAdolescence?.adolescentStories || [],
      adultStories: currentState.myAdulthood?.adultStories || [],
      achievements: currentState.myAchievements?.achievements || [],
      photoDescriptions: currentState.myPhotos?.photoDescriptions || [],
      videoDescriptions: currentState.myVideos?.videoDescriptions || [],
    };
    console.log("Selected state before saving:", selectedState);
    try {
      const { csrfToken } = currentState.auth;
      console.log("CSRF Token before making request:", csrfToken);
      const response = await axiosInstance.post(
        apiUrl,
        { state: selectedState },
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        // Handle success response as needed
        console.log("Entire state saved successfully");
      } else {
        throw new Error("Failed to save entire state");
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.error || error.message || "Unknown error";
      console.error("Error saving entire state:", errorMessage);
    }
  }

  return result;
};

export default saveFormDataMiddleware;
